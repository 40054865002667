
import Slider from 'react-slick';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { useMemo, useRef, useState } from 'react';
import AppSliderDots from '../../../../components/AppSliderDots/AppSliderDots';
import { useAppSelector } from '../../../../redux/store';
import images from '../../../../utils/images';
import './MainBannerCards.scss';

const mainBannerCardItems = [
  {
    text: 'Безопасность сделок',
    image: images.mainBannerCard1,
  },
  {
    text: 'Юридическое сопровождение',
    image: images.mainBannerCard2,
  },
  {
    text: 'Помощь в продвижении',
    image: images.mainBannerCard3,
  },
];

const MainBannerCards = () => {
  const [selectedCardId, setSelectedCardId] = useState(0);

  const screenWidth = useAppSelector(state => state.main.dimensionsData.screenSize.width);

  const centerPadding = useMemo(() => {
    const minMargin = 12;
    const maxMargin = 80;
    const marginDiff = maxMargin - minMargin;
    const minWidthResolution = 848;
    const maxWidthResolution = 1280;
    const resolutionWidthDiff = maxWidthResolution - minWidthResolution;
  
    const desktopScaling = (screenWidth - minWidthResolution) / resolutionWidthDiff;
    const dynamicMargin = minMargin + marginDiff * desktopScaling;
    const dynamicMarginClampedMin = Math.min(maxMargin, dynamicMargin);
    const pageHorizontalPadding = Math.max(dynamicMarginClampedMin, minMargin);
    return pageHorizontalPadding;
  }, [screenWidth]);

  return (
    <>
      <div className="mainBannerCardsContainerDesktop">
        {mainBannerCardItems.map((cardItem) => (
          <div key={cardItem.text} className="mainBannerCardItem">
            <div className="mainBannerCardsItemText">{cardItem.text}</div>
            <img className="mainBannerCardsItemImage" src={cardItem.image} />
          </div>
        ))}
      </div>

      <div className={'mainBannerCardsSliderContainer'}>
        <Slider
          adaptiveHeight={false}
          className={'mainBannerCardsSlider'}
          centerMode={true}
          infinite={false}
          centerPadding={`${centerPadding}px`}
          slidesToShow={1.025}
          initialSlide={0}
          swipe={true}
          speed={300}
          arrows={false}
          beforeChange={(oldIndex, newIndex) => {
            setSelectedCardId(Math.round(newIndex));
          }}
        >
          {mainBannerCardItems.map((cardItem) => (
            <div key={cardItem.text} className="mainBannerCardItemSlideContainer">
              <div className="mainBannerCardItem">
                <div className="mainBannerCardsItemText">{cardItem.text}</div>
                <img className="mainBannerCardsItemImage" src={cardItem.image} />
              </div>
            </div>
          ))}
        </Slider>

        <AppSliderDots
          count={mainBannerCardItems.length}
          selectedId={selectedCardId}
          style={{ marginTop: 8 }}
        />
      </div>
    </>
  );
};

export default MainBannerCards;
