import React, { ChangeEvent } from 'react';
import CommonInlineStyles from '../CommonInlineStyles';

const AppMaskedPhoneInput = ({
  userPhone,
  setUserPhone,
  userPhoneValid,
  setUserPhoneValid,
  setFieldReceivedInput,
  className,
  style,
  errorStyle = CommonInlineStyles.inputFieldErrorBorder,
}: {
  userPhone: string;
  setUserPhone: (phoneNumber: string) => void;
  userPhoneValid: boolean;
  setUserPhoneValid: (b: boolean) => void;
  setFieldReceivedInput: (b: boolean) => void;
  className: string;
  style?: React.CSSProperties;
  errorStyle?: React.CSSProperties,
}) => {
  const onUserPhoneFocus = () => {
    if (userPhone.length === 0) setUserPhone('7');

    if (!userPhoneValid) {
      setUserPhoneValid(true);
      setFieldReceivedInput(false);
    }
  };

  const onUserPhoneBlur = () => {
    if (userPhone.length === 1 && userPhone[0] === '7') setUserPhone('');
  };

  const onUserPhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value;
    // replace 8 with 7 if a number starts with 8
    if (input[0] === '8') input = input.replace('8', '7');
    const onlyNumbers = input.replaceAll(/\D/g, '').slice(0, 11);
    setUserPhone(onlyNumbers);
    setFieldReceivedInput(true);
  };
  
  const onUserPhonePaste = (e: React.ClipboardEvent) => {
    // disable default behaviour
    e.preventDefault();

    const input = e.clipboardData.getData('Text');
    let onlyNumbers = input.replaceAll(/\D+/g, '');
    if (onlyNumbers.length === 11) {
      // replace 8 with 7 if a number starts with 8
      if (onlyNumbers[0] === '8') onlyNumbers = onlyNumbers.replace('8', '7'); 
      setUserPhone(onlyNumbers);
    } else {
      setUserPhone(userPhone.concat(onlyNumbers).slice(0, 11));
    }
  };

  const maskFormatPhoneNumber = (input: string) => {
    let onlyNumbers = input.replaceAll(/\D/g, '').slice(0, 11);

    // replace 8 with 7 if a number starts with 8
    if (onlyNumbers[0] === '8') onlyNumbers = onlyNumbers.replace('8', '7');
  
    const countryCode = onlyNumbers.slice(0, 1);
    const areaCode = onlyNumbers.slice(1, 4);
    const rest = onlyNumbers.slice(4);
  
    // const formattedNumber = `+${countryCode} (${areaCode}) ${rest.slice(0, 3)}-${rest.slice(3, 5)}-${rest.slice(5)}`;
    let formattedNumber = '';
    if (onlyNumbers.length > 0) formattedNumber = formattedNumber.concat(`+${countryCode}`);
    if (onlyNumbers.length > 1) formattedNumber = formattedNumber.concat(` (${areaCode}`);
    if (onlyNumbers.length >= 5) formattedNumber = formattedNumber.concat(`) ${rest.slice(0, 3)}`);
    if (onlyNumbers.length >= 8) formattedNumber = formattedNumber.concat(`-${rest.slice(3, 5)}`);
    if (onlyNumbers.length >= 10) formattedNumber = formattedNumber.concat(`-${rest.slice(5)}`);

    return formattedNumber;
  }
  
  return (
    <input
      value={maskFormatPhoneNumber(userPhone)}
      onFocus={onUserPhoneFocus}
      onChange={onUserPhoneChange}
      onPaste={onUserPhonePaste}
      onBlur={onUserPhoneBlur}
      placeholder={'+7'}
      type={'tel'}
      maxLength={18} // this includes mask
      className={className}
      style={{
        ...style,
        ...(!userPhoneValid && errorStyle),
      }}
    />
  );
};

export default AppMaskedPhoneInput;
