import React from "react";

type CSSProperties = {
  [key:string]: React.CSSProperties;
};

export class StyleSheet {
  static create<Styles extends CSSProperties>(styles: Styles): Styles {
    return styles;
  };
};

export interface SocialMediaButtonData {
  icon: string;
  hoverIcon: string;
  href: string;
}

export interface MenuItem {
  text: string;
  onClick: () => void;
  disabled?: boolean;
}

export interface DimensionsData {
  screenSize: { width: number; height: number; }
  isVerticallyOriented: boolean;
}

export interface ConsultCallData {
  programDevName: string;
  durationInMonths: number;
  tags: string[];
}