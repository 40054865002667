import { useState } from "react";

export const useProcessInputFields = ({
  validatorFunctions,
  requestFunction,
  onSuccess,
  openRequestProcessedModal,
}: {
  validatorFunctions: (() => boolean)[];
  requestFunction: () => Promise<boolean>;
  onSuccess: Function;
  openRequestProcessedModal: (b: boolean) => void;
}) => {
  const [processing, setProcessing] = useState(false);
  const [requestIsSuccessful, setRequestIsSuccessful] = useState<boolean>();
  
  const onOpenRequestProcessedModal = ({ requestSuccessful }: { requestSuccessful: boolean }) => {
    openRequestProcessedModal(requestSuccessful);
    setRequestIsSuccessful(requestSuccessful);
  };

  const onProcessInputs = async () => {
    let oneOfTheInputsIsInvalid = false;
    for (let index = 0; index < validatorFunctions.length; index++) {
      const inputIsValid = validatorFunctions[index]();
      if (!inputIsValid) oneOfTheInputsIsInvalid = true;
    }
    if (oneOfTheInputsIsInvalid) return;

    // start spinner
    setProcessing(true);

    const success = await requestFunction();

    // stop spinner, show success modal, reset phone/name fields
    onOpenRequestProcessedModal({ requestSuccessful: success });
    setProcessing(false);

    if (success) {
      onSuccess();
    }
  };

  return { onProcessInputs, processing, requestIsSuccessful };
};