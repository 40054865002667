import 'rc-slider/assets/index.css';
// @ts-ignore
import AnimatedNumber from "animated-number-react";

import { useState } from 'react';
import JoinProTeamModal from '../../../../modals/JoinProTeamModal/JoinProTeamModal';
import { useModalStateHooks } from '../../../../utils/hooks/useModalStateHooks';
import { getPriceText } from '../../../../utils/stringHelper';
import './CalculatorBlock.scss';
import CalculatorBlockPricesSlider, { pricesSliderValues } from './components/CalculatorBlockPricesSlider';
import CalculatorBlockSalesSlider, { salesSliderValues } from './components/CalculatorBlockSalesSlider';

const CalculatorBlock = ({ blockRef }: { blockRef: React.RefObject<HTMLDivElement> }) => {
  const [selectedPricesValueIndex, setSelectedPricesValueIndex] = useState(1);
  const [selectedSalesValueIndex, setSelectedSalesValueIndex] = useState(1);

  const priceValue = pricesSliderValues[selectedPricesValueIndex as keyof typeof pricesSliderValues].value;
  const salesValue = salesSliderValues[selectedSalesValueIndex as keyof typeof salesSliderValues].value;
  const potentialEarningsPerMonth = priceValue * salesValue;
  const potentialEarningsPerYear = potentialEarningsPerMonth * 12;

  const { modalIsOpen, openModal, closeModal } = useModalStateHooks();

  return (
    <div className="calculatorBlockContainer" ref={blockRef}>
      <JoinProTeamModal modalIsOpen={modalIsOpen} closeModal={closeModal} />

      <div className="calculatorBlockTitle">{'Сколько вы будете зарабатывать с нами'}</div>

      <div className="calculatorBlockMainSection">
        <div className="calculatorBlockMainSectionCalculations" id="calculationSectionId">
          <div className="calculatorBlockMainSectionCalculationItem">
            <div className="calculatorBlockMainSectionCalculationsTitle">
              {'Стоимость вашей услуги или товара'}
            </div>
            <CalculatorBlockPricesSlider
              selectedValueIndex={selectedPricesValueIndex}
              setSelectedValueIndex={setSelectedPricesValueIndex}
            />
          </div>

          <div className="calculatorBlockMainSectionCalculationItem">
            <div className="calculatorBlockMainSectionCalculationsTitle">{'Объем продаж'}</div>
            <CalculatorBlockSalesSlider
              selectedValueIndex={selectedSalesValueIndex}
              setSelectedValueIndex={setSelectedSalesValueIndex}
            />
          </div>
        </div>

        <div className="calculatorBlockMainSectionResults">
          <div className="calculatorBlockMainSectionResultsOutputs">
            <AnimatedNumber
              className={"calculatorBlockMainSectionResultsOutputsValue"}
              value={potentialEarningsPerYear}
              formatValue={(n: number) => getPriceText(n.toFixed(0))}
              duration={1000}
            />
            <div className="calculatorBlockMainSectionResultsOutputsDescription">{'в год'}</div>

            <AnimatedNumber
              className={"calculatorBlockMainSectionResultsOutputsValue"}
              value={potentialEarningsPerMonth}
              formatValue={(n: number) => getPriceText(n.toFixed(0))}
              duration={1000}
            />
            <div className="calculatorBlockMainSectionResultsOutputsDescription">{'в месяц'}</div>

            <button
              className="appButton calculatorBlockMainSectionResultsOutputsButton"
              onClick={openModal}
            >
              {'Начать зарабатывать'}
            </button>
          </div>
          <div className="calculatorBlockMainSectionResultsNote">
            {
              '*Эти цифры являются примерным расчётом и могут варьироваться в зависимости от многих факторов, таких как, стоимость, спрос, сезонность и индивидуальные особенности ваших услуг и товаров.'
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalculatorBlock;