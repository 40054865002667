import { useState } from 'react';
import { useHeaderFooterMenuItems } from '../../utils/hooks/useHeaderFooterMenuItems';
import images from '../../utils/images';
import AppConsultButton from '../AppConsultButton/AppConsultButton';
import LogoBlock from '../LogoBlock/LogoBlock';
import HeaderMobileMenuModal from './HeaderMobileMenuModal';
import './Header.scss';

const Header = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { menuItems } = useHeaderFooterMenuItems({ setHeaderMenuIsOpen: setMenuIsOpen });

  if (menuIsOpen) {
    return (
      <HeaderMobileMenuModal
        closeModal={() => setMenuIsOpen(false)}
        menuItems={menuItems}
      />
    );
  }

  return (
    <div className="headerContainer">
      <LogoBlock className="headerHideOnDesktop" width={48} height={52} isClickable={true} />
      <div className="headerDesktopLeftSection">
        <LogoBlock className="headerHideOnMobile" isClickable={true} />

        <div className="headerMenuContainer headerHideOnMobile">
          {menuItems.map((item) => (
            <button
              key={item.text}
              className={`headerMenuItem ${item.disabled ? 'headerMenuItemDisabled' : ''}`}
              onClick={item.onClick}
              disabled={item.disabled}
            >
              {item.text}
            </button>
          ))}
        </div>
      </div>

      <AppConsultButton className="headerConsultButton headerHideOnMobile headerConsultButtonText" />

      <div className="headerMenuButton opacityOnHover" onClick={() => setMenuIsOpen(true)}>
        <img src={images.headerMobileMenuIcon} className="headerMenuButtonIcon" />
      </div>
    </div>
  );
};

export default Header;
