import React, { ChangeEvent, useState } from 'react';
import {
  invalidEmailText,
  invalidPhoneNumberText,
} from '../../utils/appConstants';
import { withOpacity } from '../../utils/helper';
import { useModalStateHooks } from '../../utils/hooks/useModalStateHooks';
import { useProcessInputFields } from '../../utils/hooks/useProcessInputFields';
import { useValidationEmail } from '../../utils/hooks/useValidationEmail';
import { useValidationPhone } from '../../utils/hooks/useValidationPhone';
import { callRequest } from '../../utils/requests/callRequest';
import { ConsultCallData } from '../../utils/types';
import AppMaskedPhoneInput from '../AppMaskedPhoneInput/AppMaskedPhoneInput';
import ButtonWithSpinner from '../ButtonWithSpinner/ButtonWithSpinner';
import CommonInlineStyles from '../CommonInlineStyles';
import PrivacyPolicyTextBlock from '../PrivacyPolicyTextBlock/PrivacyPolicyTextBlock';
import RequestProcessedModal from '../../modals/RequestProcessedModal/RequestProcessedModal';

const RequestCallForm = ({
  callMeButtonText = 'Подать заявку',
  containerStyle,

  containerClass,
  inputsContainerClass,
  inputFieldClass,
  checkedInputBlockClass,
  commentInputFieldClass,
  errorTextClass,
  inputErrorStyle,
  callMeButtonClass,
  callMeButtonStyle,
  personalDataNoteClass,

  closeOuterModal,
}: {
  callMeButtonText?: string;
  containerStyle?: React.CSSProperties;

  containerClass: string;
  inputsContainerClass: string;
  inputFieldClass: string;
  checkedInputBlockClass: string;
  commentInputFieldClass?: string;
  errorTextClass: string;
  inputErrorStyle?: React.CSSProperties;
  callMeButtonClass: string;
  callMeButtonStyle: React.CSSProperties;
  personalDataNoteClass: string;

  closeOuterModal?: () => void;
}) => {
  // ###################################################################################################
  const [userName, setUserName] = useState('');
  const onUserNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value);
  };

  // ###################################################################################################
  const {
    userEmail,
    setUserEmail,
    userEmailValid,
    emailFieldReceivedInput,
    onUserEmailFocus,
    onUserEmailChange,
    onUserEmailCheck,
  } = useValidationEmail();
  
  // ###################################################################################################
  const {
    userPhone,
    setUserPhone,
    userPhoneValid,
    setUserPhoneValid,
    onUserPhoneCheck,
    phoneFieldReceivedInput,
    setPhoneFieldReceivedInput,
  } = useValidationPhone();
  
  // ###################################################################################################
  const [userComment, setUserComment] = useState('');

  // ###################################################################################################
  // ###################################################################################################
  // ###################################################################################################
  const {
    modalIsOpen: requestProcessedModalIsOpen,
    openModal: openRequestProcessedModal,
    closeModal: closeRequestProcessedModal,
  } = useModalStateHooks();

  const onCloseRequestProcessedModal = () => {
    closeRequestProcessedModal();
    closeOuterModal?.();
  };

  const { processing, onProcessInputs, requestIsSuccessful } = useProcessInputFields({
    validatorFunctions: [onUserEmailCheck, onUserPhoneCheck],
    requestFunction: () => callRequest({
      userName: userName,
      userPhone: userPhone,
      userEmail: userEmail,
      userComment: userComment,
    }),
    onSuccess: () => {
      setUserPhone('');
      setUserEmail('');
      setUserName('');
    },
    openRequestProcessedModal,
  })


  // ###################################################################################################
  // ###################################################################################################
  // ###################################################################################################
  // ###################################################################################################
  const CallMeButton = ({ className, style }: { className: string; style: React.CSSProperties }) => {
    const disabled = processing || !emailFieldReceivedInput || !phoneFieldReceivedInput || !userPhoneValid;

    return (
      <ButtonWithSpinner
        onClick={onProcessInputs}
        title={callMeButtonText}
        fetchingTitle={'Обработка запроса'}
        fetching={processing}
        disabled={disabled}
        style={{
          ...style,
          backgroundColor: undefined,
          ...(disabled
            ? {
                ...CommonInlineStyles.buttonDisabled,
                backgroundColor: withOpacity(style.backgroundColor as string, 0.85),
                color: style?.color,
              }
            : undefined),
        }}
        className={className}
      />
    );
  }

  // ###################################################################################################
  return (
    <div className={containerClass}>
      <RequestProcessedModal
        modalIsOpen={requestProcessedModalIsOpen}
        closeModal={onCloseRequestProcessedModal}
        requestIsSuccessful={requestIsSuccessful}
      />

      <div className={inputsContainerClass} style={containerStyle}>
        <input
          type={'text'}
          autoComplete={'name'}
          maxLength={100}
          placeholder={'Ваше ФИО'}
          value={userName}
          onChange={onUserNameChange}
          className={`inputFieldDynamicOutline ${inputFieldClass}`}
        />

        <div className={checkedInputBlockClass}>
          <input
            type={'text'}
            placeholder={'Ваш e-mail'}
            autoComplete={'email'}
            maxLength={100}
            autoCapitalize={'none'}
            autoCorrect={''}
            value={userEmail}
            onFocus={onUserEmailFocus}
            onChange={onUserEmailChange}
            className={`inputFieldDynamicOutline ${inputFieldClass}`}
            style={!userEmailValid ? CommonInlineStyles.inputFieldErrorBorder : {}}
          />
          {!userEmailValid && <div className={errorTextClass}>{invalidEmailText}</div>}
        </div>

        <div className={checkedInputBlockClass}>
          <AppMaskedPhoneInput
            userPhone={userPhone}
            setUserPhone={setUserPhone}
            userPhoneValid={userPhoneValid}
            setUserPhoneValid={setUserPhoneValid}
            setFieldReceivedInput={setPhoneFieldReceivedInput}
            className={`inputFieldDynamicOutline ${inputFieldClass}`}
            errorStyle={inputErrorStyle}
          />
          {!userPhoneValid && <div className={errorTextClass}>{invalidPhoneNumberText}</div>}
        </div>
        {!!commentInputFieldClass && <textarea
          placeholder={'Комментарий'}
          maxLength={100}
          value={userComment}
          draggable={false}
          onChange={(e) => setUserComment(e.target.value)}
          className={`inputFieldDynamicOutline ${commentInputFieldClass}`}
        />}

        <CallMeButton className={callMeButtonClass} style={callMeButtonStyle} />
      </div>

      <PrivacyPolicyTextBlock
        className={personalDataNoteClass}
        mainText={'Нажимая на эту кнопку вы даёте свое '}
      />
    </div>
  );
};

export default RequestCallForm;
