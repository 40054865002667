import { CSSProperties, useMemo } from 'react';
import './HowItWorksCard.scss';

export interface HowItWorksCardData {
  id: number;
  stepText: string;
  title: string;
  image: string;
}

const HowItWorksCard = ({ cardData, style }: { cardData: HowItWorksCardData, style: CSSProperties }) => {
  const imageClassName = useMemo(() => {
    switch(cardData.id) {
      case 2: return 'howItWorksCardImage2';
      case 3: return 'howItWorksCardImage3';
      case 4: return 'howItWorksCardImage4';
      case 5: return 'howItWorksCardImage5';
      default: return 'howItWorksCardImage';
    }
  }, [cardData.id])

  return (
    <div className={cardData.id === 1 ? 'howItWorksCard1' : 'howItWorksCard'} style={style}>
      <div className="howItWorksCardStepText">{cardData.stepText}</div>
      <div className="howItWorksCardTitle">{cardData.title}</div>
      <img className={imageClassName} src={cardData.image} />
    </div>
  );
};

export default HowItWorksCard;