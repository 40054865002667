import AppTickerTape from '../../../../../components/AppTickerTape/AppTickerTape';
import { useAppSelector } from '../../../../../redux/store';
import { ReviewItemData } from '../reviewsArray';
import ReviewItem from './ReviewItem';
import './ReviewsBlockSlider.scss';

const ReviewsBlockSlider = ({
  reviewsData,
  reverse,
}: {
  reviewsData: ReviewItemData[];
  reverse?: boolean;
}) => {
  const reviewModalText = useAppSelector(state => state.main.reviewModalText);

  return (
    <div className="reviewsBlockSliderContainer">
      <AppTickerTape speed={1} rtl={reverse} pause={!!reviewModalText} pauseOnHover>
        {reviewsData.map((reviewData) => (
          <ReviewItem key={reviewData.reviewerCredentials} reviewItemData={reviewData} />
        ))}
      </AppTickerTape>
    </div>
  );
};

export default ReviewsBlockSlider;