import { useDispatch } from 'react-redux';
import { setReviewModalText } from '../../../../../redux/mainReducer';
import images from '../../../../../utils/images';
import { ReviewItemData } from '../reviewsArray';
import ReviewItemSocialMedia from './ReviewItemSocialMedia';
import './ReviewItem.scss';

const maxReviewLength = 120;
const getShortenedReviewText  = (input: string) => {
  let reviewText = input;
  if (input.length < maxReviewLength) return { reviewText: input, changed: false };

  const trimmedText = input.slice(0, maxReviewLength);
  const lastSpaceIndex = trimmedText.lastIndexOf(' ');

  // Check if the last character is a space or the input can be split without cutting a word
  if (lastSpaceIndex > 0) {
    reviewText = input.slice(0, lastSpaceIndex) + '...';
    return { reviewText, changed: true };
  }
  
  reviewText = trimmedText + '...';
  return { reviewText, changed: true };
};

const ReviewItem = ({
  reviewItemData,
}: {
  reviewItemData: ReviewItemData;
}) => {
  const { reviewText: reviewTextRaw, reviewerCredentials, likesCount } = reviewItemData;
  const { reviewText, changed } = getShortenedReviewText(reviewTextRaw);

  // ###################################################################################################
  const dispatch = useDispatch();
  const onExpandButtonClick = () => {
    dispatch(setReviewModalText(reviewTextRaw));
  }
  // ###################################################################################################

  if (!reviewTextRaw) return <ReviewItemSocialMedia />

  return (
    <div className="reviewItemContainer" draggable={false}>
      {reviewText}

      <div>
        {changed && (
          <button className="reviewItemExpandButton" onClick={onExpandButtonClick}>
            {'Показать полностью...'}
          </button>
        )}
        <div className="reviewItemReviewerInfoBlock">
          {reviewerCredentials}

          <div className="reviewItemLikesCounter">
            <img className="reviewItemLikesIcon" src={images.heart} />
            {likesCount}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewItem;
