import { sleep } from "../helper";

// TODO
export const registerForWebinarRequest = async ({
  userCredentials,
  userEmail,
  userPhone,
}: {
  userCredentials: string;
  userEmail: string;
  userPhone: string;
}) => {
  const userPhoneFormatted = userPhone.replaceAll(/\D/g, '');
  await sleep(1000);
  return true;
};