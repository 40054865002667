import { CSSProperties, ReactNode } from 'react';
import images from '../../utils/images';
import './AppTooltip.scss';

const AppTooltip = ({
  children,
  className,
  style,
  textStyle,
}: {
  children: ReactNode | ReactNode[];
  className?: string;
  style?: CSSProperties;
  textStyle?: CSSProperties;
}) => {
  return (
    <div className={`tooltipContainer ${className || ''}`} style={style}>
      <div className="tooltipContents" style={textStyle}>{children}</div>

      <img className="tooltipPopupTriangle" src={images.whatYouGetTriangleForPopup} />
    </div>
  );
};

export default AppTooltip;