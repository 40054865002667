import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { MarkObj } from 'rc-slider/lib/Marks';
import { useMemo } from 'react';
import './CalculatorBlockSlider.scss';
import CalculatorBlockSliderHandleWithTooltip from './CalculatorBlockSliderHandleWithTooltip';

type SliderData = Record<number, MarkObj & { value: number }>;

export const pricesSliderValues = {
  0: { value:  1000 },
  1: { value:  5750 },
  2: { value: 30000 },
  3: { value: 75000 },
}

const CalculatorBlockPricesSlider = ({
  selectedValueIndex,
  setSelectedValueIndex,
}: {
  selectedValueIndex: number;
  setSelectedValueIndex: (n: number) => void;
}) => {
  const withStyle = (id: number) => {
    if (id === selectedValueIndex) {
      return { color: '#2C5282' };
    } else return undefined;
  }

  const marks = useMemo(() => ({
    0: { style: { ...withStyle(0), }, label: '500 ₽ – 1,500 ₽' },
    1: { style: { ...withStyle(1), }, label: '1,500 ₽ – 10,000 ₽' },
    2: { style: { ...withStyle(2), }, label: '10,000 ₽ – 50,000 ₽' },
    3: { style: { ...withStyle(3), }, label: '50,000 ₽ и выше' },
  }), [selectedValueIndex]);

  const boundaryElementId = 'calculationPricesSectionId';

  return (
    <div className="calculatorBlockSliderContainer calculatorBlockPricesSliderContainer" id={boundaryElementId}>
      <div className="calculatorBlockSliderOuterRailing" />
      <Slider
        min={0}
        max={Object.keys(marks).length - 1}
        marks={marks}
        dotStyle={{ display: 'none' }}
        handleRender={(node) => (
          <CalculatorBlockSliderHandleWithTooltip
            handleNode={node}
            label={marks[selectedValueIndex as keyof typeof marks].label as string}
            boundaryElementId={boundaryElementId}
          />
        )}
        track={false}
        included={false}
        step={1}
        defaultValue={1}
        activeDotStyle={{ display: 'none' }}
        onChange={(value) => setSelectedValueIndex(value as number)}
      />
      <div className="calculatorBlockPricesSliderMobileMarksContainer">
        <div>{'500'}</div>
        <div>{'50,000 +'}</div>
      </div>
    </div>
  );
};

export default CalculatorBlockPricesSlider;