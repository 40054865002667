import { ReactNode, useMemo } from 'react';
import { useDisableScroll } from '../../utils/hooks/useDisableScroll';
import images from '../../utils/images';
import './RequestProcessedModal.scss';

const RequestProcessedModal = ({
  successTitle = 'Заявка получена!',
  successMessage = 'Держите телефон под рукой, наши специалисты свяжутся с вами в ближайшее время!',
  disablingScrollHandledOutside,
  modalIsOpen,
  closeModal,
  requestIsSuccessful,
}: {
  successTitle?: string;
  successMessage?: string | ReactNode;
  disablingScrollHandledOutside?: boolean;
  modalIsOpen: boolean;
  closeModal: () => void;
  requestIsSuccessful?: boolean;
}) => {
  // if there is outer modal, it should handle disabling scroll by itself
  useDisableScroll(!disablingScrollHandledOutside && modalIsOpen);

  const modalData = useMemo(() => {
    if (requestIsSuccessful)
      return {
        statusImage: images.modalSuccess,
        statusImageClass: 'requestProcessedModalIconSuccess',
        title: successTitle,
        message: successMessage,
        buttonText: 'Спасибо',
      };
    else
      return {
        statusImage: images.modalFailure,
        statusImageClass: 'requestProcessedModalIconFailure',
        title: 'Что-то пошло не так!',
        message: 'Произошла неизвестная ошибка. Попробуйте повторить попытку позже.',
        buttonText: 'Ок',
      };
  }, [requestIsSuccessful]);

  if (!modalIsOpen) return null;

  return (
    <div className="modalOverlay">
      <div className="requestProcessedModalContainer">
        <img src={modalData.statusImage} alt="" className={modalData.statusImageClass} />
        <div className="requestProcessedModalTitle">{modalData.title}</div>
        <div className="requestProcessedModalText">{modalData.message}</div>
        <button className="appButton requestProcessedModalButton" onClick={closeModal}>
          {modalData.buttonText}
        </button>
      </div>
    </div>
  );
};

export default RequestProcessedModal;
