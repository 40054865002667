import { CSSProperties, ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CookiesConsentNotification from '../components/CookiesConsentNotification/CookiesConsentNotification';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import WebinarTape from '../components/WebinarTape/WebinarTape';
import WaitGetFreeConsultModal from '../modals/WaitGetFreeConsultModal/WaitGetFreeConsultModal';
import { RootState } from '../redux/store';
import { useModalStateHooks } from '../utils/hooks/useModalStateHooks';
import { useShowModalBeforeClosingWebsite } from '../utils/hooks/useShowModalBeforeClosingWebsite';
import { useTrackScreenSize } from '../utils/hooks/useTrackScreenSize';
import { isProd } from '../utils/requestApi';
import { getBuildDateString } from '../utils/stringHelper';
import './BaseLayout.scss';

const buildDateInfoString = `#upd. ${getBuildDateString()} (${process.env.REACT_APP_ENV})`;

const BaseLayout = ({
  content,
  hideFooter,
  style,
}: {
  content: ReactNode;
  hideFooter?: boolean;
  style?: CSSProperties;
}) => {
  // useSetTitle();
  useTrackScreenSize();
  const blockInterface = useSelector((state: RootState) => state.main.blockInterface);

  useEffect(() => {
    console.log(buildDateInfoString);
    // yandexMetricsInit();
  }, []);

  const {
    modalIsOpen: waitGetFreeConsultModalIsOpen,
    openModal: openWaitGetFreeConsultModal,
    closeModal: closeWaitGetFreeConsultModal,
  } = useModalStateHooks();
  
  useShowModalBeforeClosingWebsite({ openModal: openWaitGetFreeConsultModal });

  return (
    <div className="appContainer" style={style}>
      {!isProd && <div className="buildDateInfoString">{buildDateInfoString}</div>}

      {blockInterface && <div className="disablePageOverlay" />}

      <WaitGetFreeConsultModal
        modalIsOpen={waitGetFreeConsultModalIsOpen}
        closeModal={closeWaitGetFreeConsultModal}
      />

      <CookiesConsentNotification />

      <WebinarTape />

      <Header />

      {content}

      {!hideFooter && <Footer />}
    </div>
  );
};

export default BaseLayout;
