import AppConsultButton from '../../../../../components/AppConsultButton/AppConsultButton';
import images from '../../../../../utils/images';
import { whatYouGetBlockPeoplePhotos } from '../whatYouGetBlockPeoplePhotos';
import './WhatYouGetBlockLargeItem.scss';
import WhatYouGetBlockThisIsAPlaceForYou from './WhatYouGetBlockThisIsAPlaceForYou';

export const whatYouGetBlockLargeItemContents = {
  title: 'Сообщество профессионалов',
  description: 'Представьте себе клуб, где все понимают, о чем вы говорите. У нас собирается каманда лучших специалистов в области ЗОЖ и ментального здоровья. Обменивайтесь опытом, получайте советы и вдохновение, находите партнёров для совместных проектов. И кто знает, может быть, вы найдёте здесь не только коллег, но и новых друзей?',
  withUsTitle: 'Уже с нами:',
}

const WhatYouGetBlockLargeItem = () => {
  return (
    <div
      className="whatYouGetBlockLargeItem"
      aria-rowspan={2}
      style={{ background: 'linear-gradient(335.5deg, #BCE5FA -0.03%, #D2EAF3 47.52%)' }}
    >
      <div className="whatYouGetBlockLargeItemDescriptionBlock">
        <div>
          <div className="whatYouGetBlockItemTitle">
            {whatYouGetBlockLargeItemContents.title}
          </div>
          <div className="whatYouGetBlockItemDescription">
            {whatYouGetBlockLargeItemContents.description}
          </div>
        </div>
        <AppConsultButton className="whatYouGetBlockHideOnDesktop whatYouGetBlockConsultButton" />
      </div>
      <div className="whatYouGetBlockLargeItemAlreadyWithUsBlock">
        <div className="whatYouGetBlockLargeItemAlreadyWithUsBlockTitle">
          {whatYouGetBlockLargeItemContents.withUsTitle}
        </div>
        <div className="whatYouGetBlockLargeItemAlreadyWithUsBlockPeoplePhotos">
          {whatYouGetBlockPeoplePhotos.map((imageSrc) => (
            <img key={imageSrc} className="whatYouGetBlockPeolePhotosItem" src={imageSrc} />
          ))}

          <WhatYouGetBlockThisIsAPlaceForYou />
        </div>
      </div>

      <img
        className="whatYouGetBlockLargeItemBackgroundImage"
        src={images.whatYouGetItem7backgroundDesktopRight}
      />
    </div>
  );
};

export default WhatYouGetBlockLargeItem;
