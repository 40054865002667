import { useEffect } from 'react';
import images from '../../utils/images';
import { MenuItem } from '../../utils/types';
import AppConsultButton from '../AppConsultButton/AppConsultButton';
import LogoBlock from '../LogoBlock/LogoBlock';
import './Header.scss';

const HeaderMobileMenuModal = ({
  closeModal,
  menuItems,
}: {
  closeModal: () => void;
  menuItems: MenuItem[];
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const onMenuItemClick = (menuItem: MenuItem) => {
    closeModal();
    menuItem.onClick();
  };

  return (
    <div className="headerMenuModalContainer">

      <div className="headerMenuModalHeaderContainer">

        <LogoBlock width={48} height={52} isClickable={true} onClick={closeModal}/>

        <button className="headerMenuButton opacityOnHover" onClick={closeModal}>
          <img src={images.headerMobileMenuIconClose} className="headerMenuButtonIconClose" />
        </button>

      </div>

      <div className="headerMenuModalMainBlock">

        <div className="headerMenuModalItemsBlock">
          {menuItems.map((item) => (
            <button
              key={item.text}
              className={`headerMenuItem opacityOnHover ${item.disabled ? 'headerMenuItemDisabled' : ''}`}
              onClick={() => onMenuItemClick(item)}
              disabled={item.disabled}
            >
              {item.text}
            </button>
          ))}
        </div>

        <AppConsultButton className="headerMenuModalConsultButton" />
      </div>
    </div>
  );
};

export default HeaderMobileMenuModal;
