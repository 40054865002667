import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSectionToReturnTo, setSectionToScrollTo } from '../../redux/mainReducer';
import { RootState } from '../../redux/store';
import { SectionName } from '../../utils/enums';
import { scrollToBlockRef } from '../../utils/helper';
import BaseLayout from '../BaseLayout';
import MainBanner from './components/1.MainBanner/MainBanner';
import MainBannerCards from './components/2.MainBannerCards/MainBannerCards';
import VideoBlock from './components/3.VideoBlock/VideoBlock';
import WhatYouGetBlock from './components/4.WhatYouGetBlock/WhatYouGetBlock';
import RequirementsForSpecialists from './components/5.RequirementsForSpecialists/RequirementsForSpecialists';
import HowItWorks from './components/6.HowItWorks/HowItWorks';
import WorkingWithEtnaApp from './components/7.WorkingWithEtnaApp/WorkingWithEtnaApp';
import CalculatorBlock from './components/8.CalculatorBlock/CalculatorBlock';
import ReviewsBlock from './components/9.ReviewsBlock/ReviewsBlock';
import JoinUsBlock from './components/10.JoinUsBlock/JoinUsBlock';
import FAQ from './components/11.FAQ/FAQ';
import ConsultBlock from './components/12.ConsultBlock/ConsultBlock';
import './MainPage.scss';

const MainPage = () => {
  const howItWorksRef = useRef<HTMLDivElement>(null);
  const workingWithEtnaAppRef = useRef<HTMLDivElement>(null);
  const calculatorRef = useRef<HTMLDivElement>(null);
  const etnaAppRef    = useRef<HTMLDivElement>(null);
  const faqRef        = useRef<HTMLDivElement>(null);
  const consultRef    = useRef<HTMLDivElement>(null);

  const scrollToSection = (sectionName: SectionName | null) => {
    switch (sectionName) {
      case SectionName.HowItWorks:         scrollToBlockRef(howItWorksRef); break;
      case SectionName.WorkingWithEtnaApp: scrollToBlockRef(workingWithEtnaAppRef); break;
      case SectionName.Calculator:         scrollToBlockRef(calculatorRef); break;
      case SectionName.EtnaApp:            scrollToBlockRef(etnaAppRef);    break;
      case SectionName.FAQ:                scrollToBlockRef(faqRef);        break;
      case SectionName.Consult:            scrollToBlockRef(consultRef);    break;
      
      default: return;
    }
  }
  
  const dispatch = useDispatch();
  const sectionNameToReturnTo = useSelector((state: RootState) => state.main.sectionNameToReturnTo);
  const sectionNameToScrollTo = useSelector((state: RootState) => state.main.sectionNameToScrollTo);

  useEffect(() => {
    if (sectionNameToReturnTo) {
      // if we have values for both sectionNameToReturnTo AND sectionNameToScrollTo,
      // sectionNameToScrollTo should have priority and sectionNameToReturnTo should be ignored & reset
      if (!sectionNameToScrollTo) scrollToSection(sectionNameToReturnTo);

      dispatch(setSectionToReturnTo(null));
    }
  }, []);

  useEffect(() => {
    if (sectionNameToScrollTo) {
      scrollToSection(sectionNameToScrollTo);
      dispatch(setSectionToScrollTo(null));
    }
  }, [sectionNameToScrollTo]);

  return (
    <BaseLayout
      content={
        <div className="mainPageContainerInner">
          <MainBanner />
          <MainBannerCards />
          {/* <VideoBlock /> */}
          <WhatYouGetBlock />
          <RequirementsForSpecialists />
          <HowItWorks blockRef={howItWorksRef} />
          <WorkingWithEtnaApp blockRef={workingWithEtnaAppRef} />
          <CalculatorBlock blockRef={calculatorRef} />
          <ReviewsBlock />
          <JoinUsBlock blockRef={etnaAppRef} />
          <FAQ blockRef={faqRef} />
          <ConsultBlock blockRef={consultRef} />
        </div>
      }
    />
  );
}

export default MainPage;
