import ExpandedReviewModal from './components/ExpandedReviewModal';
import ReviewsBlockSlider from './components/ReviewsBlockSlider';
import { reviewsArrayLine1, reviewsArrayLine2 } from './reviewsArray';
import './ReviewsBlock.scss';

const ReviewsBlock = () => {

  return (
    <div className="reviewsBlockContainer">
      <ExpandedReviewModal />

      <div className="reviewsBlockTitle">{'Реальные отзывы о работе с EtnaApp'}</div>
      <ReviewsBlockSlider reviewsData={reviewsArrayLine1} reverse />
      <ReviewsBlockSlider reviewsData={reviewsArrayLine2} />
    </div>
  );
};

export default ReviewsBlock;