import { PagePath } from "./enums";
import images from "./images";

export const sleep = (ms?: number) => new Promise(r => setTimeout(r, ms || 0));

export const scrollToTop = (smooth = true) => {
  sleep().then(() => window.scrollTo({ top: 0, behavior: smooth ? 'smooth' : undefined }))
}

export const scrollToBlockRef = (blockRef: React.RefObject<HTMLDivElement>, offset = -20) => {
  sleep().then(() => {
    const elementPosition = blockRef.current?.getBoundingClientRect().top!;
    const offsetPosition = elementPosition + window.scrollY + offset;
  
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  })
};

export const validateEmail = (input: string) => {
  const emailValidationRegExp = /^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@(?:[a-zA-Z0-9]+\.)+[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*$/;
  const isValid = emailValidationRegExp.test(input);
  return isValid;
};

export const validatePhoneNumber = (input: string) => {
  const onlyNumbers = input.replaceAll(/\D/g, '');
  const isValid = onlyNumbers.length === 11;
  return isValid;
};

export const withOpacity = (hex: string, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g)?.map(x => parseInt(x, 16))!;
  return `rgba(${r},${g},${b},${alpha})`;
};