import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { useMemo, useRef, useState } from 'react';
import AppSliderDots from '../../../../../components/AppSliderDots/AppSliderDots';
import { useAppSelector } from '../../../../../redux/store';
import WhatYouGetBlockSmallItems from './WhatYouGetBlockSmallItems';
import './WhatYouGetBlockMobileSlider.scss';
import './WhatYouGetBlockSmallItems.scss';
import AppConsultButton from '../../../../../components/AppConsultButton/AppConsultButton';
import images from '../../../../../utils/images';
import { whatYouGetBlockPeoplePhotos } from '../whatYouGetBlockPeoplePhotos';
import { whatYouGetBlockLargeItemContents } from './WhatYouGetBlockLargeItem';
import WhatYouGetBlockThisIsAPlaceForYou from './WhatYouGetBlockThisIsAPlaceForYou';

const SliderItemsMobile = WhatYouGetBlockSmallItems();
SliderItemsMobile.splice(1, 1);
SliderItemsMobile.unshift(WhatYouGetBlockSmallItems()[1]);

const WhatYouGetBlockMobileSlider = () => {
  const [selectedItemId, setSelectedItemId] = useState(0);

  const screenWidth = useAppSelector((state) => state.main.dimensionsData.screenSize.width);

  const centerPadding = useMemo(() => {
    const minMargin = 12;
    const maxMargin = 80;
    const marginDiff = maxMargin - minMargin;
    const minWidthResolution = 848;
    const maxWidthResolution = 1280;
    const resolutionWidthDiff = maxWidthResolution - minWidthResolution;

    const desktopScaling = (screenWidth - minWidthResolution) / resolutionWidthDiff;
    const dynamicMargin = minMargin + marginDiff * desktopScaling;
    const dynamicMarginClampedMin = Math.min(maxMargin, dynamicMargin);
    const pageHorizontalPadding = Math.max(dynamicMarginClampedMin, minMargin);
    return pageHorizontalPadding;
  }, [screenWidth]);

  return (
    <div className={'whatYouGetBlockMobileSliderContainer'}>
      <Slider
        adaptiveHeight={false}
        className={'whatYouGetBlockMobileSlider'}
        centerMode={true}
        infinite={false}
        centerPadding={`${centerPadding}px`}
        slidesToShow={1.15}
        initialSlide={0}
        swipe={true}
        speed={300}
        arrows={false}
        beforeChange={(oldIndex, newIndex) => {
          setSelectedItemId(Math.round(newIndex));
        }}
      >
        {SliderItemsMobile.map((Item, index) => (
          <div key={index}>
            <Item.type {...Item.props} className={`${Item.props.className} whatYouGetBlockMobileSliderItem`} />
          </div>
        ))}

        <div key={6}>
          <div
            className="whatYouGetBlockLargeItemMobile1"
            aria-rowspan={2}
            style={{ background: 'linear-gradient(335.5deg, #BCE5FA -0.03%, #D2EAF3 47.52%)' }}
          >
            <div className="whatYouGetBlockLargeItemDescriptionBlockMobile">
              <div>
                <div className="whatYouGetBlockItemTitle">
                  {whatYouGetBlockLargeItemContents.title}
                </div>
                <div className="whatYouGetBlockItemDescription">
                  {whatYouGetBlockLargeItemContents.description}
                </div>
              </div>
              <AppConsultButton className="whatYouGetBlockHideOnDesktop whatYouGetBlockConsultButton" />
            </div>
          </div>
        </div>


        <div key={7}>
          <div
            className="whatYouGetBlockLargeItemMobile2 whatYouGetBlockMobileSliderItem"
            aria-rowspan={2}
            style={{ background: 'linear-gradient(-335.5deg, #BCE5FA -0.03%, #D2EAF3 47.52%)' }}
          >
            <div className="whatYouGetBlockLargeItemAlreadyWithUsBlock">
              <div className="whatYouGetBlockLargeItemAlreadyWithUsBlockTitle">
                {whatYouGetBlockLargeItemContents.withUsTitle}
              </div>
              <div className="whatYouGetBlockLargeItemAlreadyWithUsBlockPeoplePhotos">
                {whatYouGetBlockPeoplePhotos.map((imageSrc) => (
                  <img key={imageSrc} className="whatYouGetBlockPeolePhotosItem" src={imageSrc} />
                ))}

                <WhatYouGetBlockThisIsAPlaceForYou />
              </div>
            </div>

            <img
              className="whatYouGetBlockLargeItemBackgroundImageMobile"
              src={images.whatYouGetItem7backgroundDesktopRight}
            />
          </div>
        </div>
      </Slider>

      <AppSliderDots count={8} selectedId={selectedItemId} style={{ marginTop: 3 }}/>
    </div>
  );
};

export default WhatYouGetBlockMobileSlider;
