import React, { useState } from 'react';
import { isIOS, isMacOs } from 'react-device-detect';
import {
  appStoreHref,
  companyMail,
  companyMailHref,
  googlePlayHref,
  telegramBotHref,
  telegramBotName
} from '../../../../utils/appConstants';
import FaqItemCollapsible, { QuestionItem } from './FaqItemCollapsible';
import './FAQ.scss';

const googlePlayLinkBlock = <a className='faqBlockAnswerLink link opacityOnHover' href={googlePlayHref}>{'скачать на Android'}</a>;
const appStoreLinkBlock = <a className='faqBlockAnswerLink link opacityOnHover' href={appStoreHref}>{'скачать на IOS'}</a>;
const appLinkBlock = isIOS || isMacOs ? <>{appStoreLinkBlock}, {googlePlayLinkBlock}</> : <>{googlePlayLinkBlock}, {appStoreLinkBlock}</>;

const guideLinkBlock = <a className='faqBlockAnswerLink link opacityOnHover' href={'TODO'} target='_blank'>{'«Как начать зарабатывать с EtnaApp»'}</a>;

const companyMailLinkBlock = <a className='faqBlockAnswerLink link opacityOnHover' href={companyMailHref}>{companyMail}</a>;
const telegramBotLinkBlock = <a className='faqBlockAnswerLink link opacityOnHover' href={telegramBotHref}>{telegramBotName}</a>;

const questions: QuestionItem[] = [
  { question: 'Что такое PRO-аккаунт?', answer: 'Это статус пользователя в приложении Etna App, который подтвердил свой профессиональный и налоговый статус.' },
  { question: 'Какие услуги и продукты я могу создавать и продавать с EtnaApp?', answer: <div className='faqBlockAnswerText'>С EtnaApp вы можете создавать и продавать широкий ассортимент товаров и услуг в области здорового образа жизни (ЗОЖ) и ментального здоровья. Это могут быть:<ul><li>Продукты для улучшения физического здоровья - тренировки, планы питания, консультации, курсы.</li><li>Услуги по поддержке ментального благополучия - психологические консультации, гайды, курсы.</li></ul></div> },
  { question: 'Как подать заявку на создание PRO-аккаунта EtnaApp?', answer: <div className='faqBlockAnswerText'>Заявку на создание PRO-аккаунта можно подать через мобильное приложение EtnaApp ({appLinkBlock}). Для этого необходимо зайти в раздел "Настройки" и следовать инструкциям. При подаче заявки требуется предоставить информацию об образовании, включая дипломы и сертификаты, подтверждающие вашу экспертизу в соответствующей области, а также личные паспортные данные для вашей идентификации. Заявка рассматривается в течение 3-х суток.</div> },
  { question: 'Как EtnaApp помогает в продвижении моих продуктов?', answer: 'Помимо предоставления платформы для взаимодействия с клиентами, EtnaApp помогает выстраивать клиентский сервис для ваших продуктов и правильно оформлять их, чтобы они были привлекательны и понятны для покупателей. Для продвижения и продаж наш отдел маркетинга использует различные инструменты и социальные сети, обеспечивая широкий охват аудитории. Мы поддерживаем вас на каждом этапе, чтобы вы могли сосредоточиться на любимом деле и предоставлении качественных услуг, в то время как мы занимаемся продвижением ваших продуктов.' },
  { question: 'Могу ли я работать с клиентами в гибком графике?', answer: 'Конечно! Вы сами определяете время взаимодействия с клиентами. После покупки продукта автоматически создается чат между вами и покупателем в мобильном приложении EtnaApp, где вы можете обсудить все детали дальнейшего взаимодействия.' },
  { question: 'Как получить оплату с моих продаж и какая комиссия взимается?', answer: <div className='faqBlockAnswerText'>Мы используем прозрачную систему комиссий за пользование платформой и продвижение, которая зависит от объема ваших продаж. Никаких скрытых комиссий и штрафов. При заполнении карточки продукта вы сразу видите размер комиссии и можете установить цену с ее учетом. Вы получаете ежемесячные выплаты на свой расчетный счет после вычета фиксированной комиссии во время вывода средств. Более подробно про систему комиссий и выплат вы можете узнать в нашем {guideLinkBlock}.</div> },
  { question: 'Какие преимущества у PRO-аккаунта EtnaApp?', answer: <div className='faqBlockAnswerText'><ul><li>Возможность размещать и продавать свои продукты на сайте и в приложении EtnaApp</li><li>Бесплатная помощь EtnaApp в выстраивании клиентского сервиса, оформлении, продвижении и продаже ваших продуктов.</li><li>Уникальный визуальный стиль PRO-аккаунта выделяет ваш профиль среди других пользователей, что делает его более заметным и привлекает внимание потенциальных клиентов.</li></ul></div> },
  { question: 'Кто может покупать мои продукты и услуги?', answer: 'В настоящее время продукты EtnaApp могут покупать все пользователи с картами РФ. Мы также работаем над внедрением других способов оплаты, чтобы сделать процесс покупки еще удобнее.' },
  { question: 'Безопасны ли мои данные с EtnaApp?', answer: 'Да, ваши данные надежно защищены. Мы используем современные методы шифрования и соблюдаем высокие стандарты безопасности для защиты вашей информации.' },
  { question: 'Как EtnaApp обеспечивает безопасность сделок?', answer: 'Мы обеспечиваем максимальную безопасность всех транзакций на платформе EtnaApp. Безопасность сделок необходима для возможного предотвращения мошенничества, она включает защиту данных покупателей и продавцов, безопасные методы оплаты, а также подтверждение выполнения услуги с обеих сторон.' },
  { question: 'Какое вы предоставляете юридическое сопровождение?', answer: 'Мы предоставляем юридическое сопровождение, включая помощь с оформлением документов, связанных с продажей ваших продуктов на платформе.' },
  { question: 'Как задать вопрос?', answer: <div className='faqBlockAnswerText'>Вы можете написать нам на почту {companyMailLinkBlock}, либо в телеграм-бот {telegramBotLinkBlock}</div> },
];

const questionsHalfLength = Math.ceil(questions.length / 2);

const FAQ = ({ blockRef }: { blockRef: React.RefObject<HTMLDivElement> }) => {
  const [faqItems, setFaqItems] = useState(questions);

  const onOpenFaqItem = (id: number) => {
    const updatedFaqItems = [...faqItems].map((faqItem, index) => ({ ...faqItem, expanded: id === index }));
    setFaqItems(updatedFaqItems);
  }

  const onCloseFaqItem = (id: number) => {
    const updatedFaqItems = [...faqItems];
    updatedFaqItems[id].expanded = false;
    setFaqItems(updatedFaqItems);
  }

  return (
    <div className="faqBlockContainer" ref={blockRef} id="faq-block">
      <div className="faqBlockTitle">{'Часто спрашивают'}</div>

      <div className="faqBlockContainerInner">
        <div className="faqBlockItemsContainer">
          {faqItems.slice(0, questionsHalfLength).map((faqItem, index) => (
            <FaqItemCollapsible
              key={faqItem.question}
              faqItem={faqItem}
              onOpen={() => onOpenFaqItem(index)}
              onClose={() => onCloseFaqItem(index)}
            />
          ))}
        </div>
        <div className="faqBlockItemsContainer">
          {faqItems.slice(questionsHalfLength).map((faqItem, index) => (
            <FaqItemCollapsible
              key={faqItem.question}
              faqItem={faqItem}
              onOpen={() => onOpenFaqItem(index + questionsHalfLength)}
              onClose={() => onCloseFaqItem(index + questionsHalfLength)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default FAQ;
