import AppMaskedPhoneInput from '../../components/AppMaskedPhoneInput/AppMaskedPhoneInput';
import ButtonWithSpinner from '../../components/ButtonWithSpinner/ButtonWithSpinner';
import CommonInlineStyles from '../../components/CommonInlineStyles';
import PrivacyPolicyTextBlock from '../../components/PrivacyPolicyTextBlock/PrivacyPolicyTextBlock';
import { invalidPhoneNumberText } from '../../utils/appConstants';
import { withOpacity } from '../../utils/helper';
import { useCloseModalOnClickOutside } from '../../utils/hooks/useCloseModalOnClickOutside';
import { useDisableScroll } from '../../utils/hooks/useDisableScroll';
import { useModalStateHooks } from '../../utils/hooks/useModalStateHooks';
import { useProcessInputFields } from '../../utils/hooks/useProcessInputFields';
import { useValidationPhone } from '../../utils/hooks/useValidationPhone';
import images from '../../utils/images';
import { waitGetFreeConsultRequest } from '../../utils/requests/waitGetFreeConsultRequest';
import { colors } from '../../utils/themeConstants';
import ModalCloseButton from '../ModalCloseButton/ModalCloseButton';
import RequestProcessedModal from '../RequestProcessedModal/RequestProcessedModal';
import './WaitGetFreeConsultModal.scss';

const WaitGetFreeConsultModal = ({
  modalIsOpen,
  closeModal,
}: {
  modalIsOpen: boolean;
  closeModal: () => void;
}) => {
  useDisableScroll(modalIsOpen);
  const { modalRef } = useCloseModalOnClickOutside({ closeModal });

  const {
    userPhone,
    setUserPhone,
    userPhoneValid,
    setUserPhoneValid,
    onUserPhoneCheck,
    phoneFieldReceivedInput,
    setPhoneFieldReceivedInput,
  } = useValidationPhone();

  const {
    modalIsOpen: requestProcessedModalIsOpen,
    openModal: openRequestProcessedModal,
    closeModal: closeRequestProcessedModal,
  } = useModalStateHooks();

  const onCloseRequestProcessedModal = () => {
    closeRequestProcessedModal();
    closeModal();
  };

  const { processing, onProcessInputs, requestIsSuccessful } = useProcessInputFields({
    validatorFunctions: [onUserPhoneCheck],
    requestFunction: () => waitGetFreeConsultRequest({
      userPhone: userPhone,
    }),
    onSuccess: () => {
      setUserPhone('');
    },
    openRequestProcessedModal,
  })

  const disabled = processing || !phoneFieldReceivedInput || !userPhoneValid;
  
  if (!modalIsOpen) return null;

  return (
    <div className="modalOverlay">
      <RequestProcessedModal
        disablingScrollHandledOutside={true}
        modalIsOpen={requestProcessedModalIsOpen}
        closeModal={onCloseRequestProcessedModal}
        requestIsSuccessful={requestIsSuccessful}
      />

      <div className="waitGetFreeConsultModalContainer" ref={modalRef}>
        <ModalCloseButton closeModal={closeModal} />


        <div className="waitGetFreeConsultModalSection1">
          <div className="waitGetFreeConsultModalSection1Title">{'Подождите!'}</div>

          <div className="waitGetFreeConsultModalSection1Description">
            Получите
            <span style={{ color: '#0EC1C9' }}> бесплатную </span> 
            консультацию и начните путь к успеху уже сегодня!
          </div>

          <div className="waitGetFreeConsultModalSection1InputContainer">
            <AppMaskedPhoneInput
              userPhone={userPhone}
              setUserPhone={setUserPhone}
              userPhoneValid={userPhoneValid}
              setUserPhoneValid={setUserPhoneValid}
              setFieldReceivedInput={setPhoneFieldReceivedInput}
              className={`inputFieldDynamicOutline waitGetFreeConsultModalSection1InputField`}
            />
            {!userPhoneValid && <div className="inputError">{invalidPhoneNumberText}</div>}
          </div>

          <ButtonWithSpinner
            onClick={onProcessInputs}
            title={'Бесплатная консультация'}
            fetchingTitle={'Обработка запроса'}
            fetching={processing}
            disabled={disabled}
            style={{
              backgroundColor: undefined,
              ...(disabled
                ? {
                    ...CommonInlineStyles.buttonDisabled,
                    backgroundColor: withOpacity(colors.accentDark, 0.85),
                  }
                : undefined),
            }}
            className="waitGetFreeConsultModalSection1Button"
          />

          <PrivacyPolicyTextBlock
            className="waitGetFreeConsultModalSection1PrivacyPolicy"
            mainText={'Нажимая на эту кнопку вы даёте свое '}
          />
        </div>

        <div className="waitGetFreeConsultModalSection2">
          <div className="waitGetFreeConsultModalSection2NoteContainer">
            <div className="waitGetFreeConsultModalSection2NoteIconContainer">
              <img
                className="waitGetFreeConsultModalSection2NoteIcon"
                src={images.waitGetFreeConsultClock}
              />
            </div>
            <div className="waitGetFreeConsultModalSection2NoteText">
              Уникальное предложение
              <br/><span style={{ color: '#0EC1C9' }}>доступно только сейчас!</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaitGetFreeConsultModal;