import { useDispatch } from "react-redux";
import { setSectionToScrollTo } from "../../redux/mainReducer";
import { SectionName, PagePath } from "../enums";
import { scrollToTop } from "../helper";
import { useAppNavigate } from "./useAppNavigate";

export const useOnReturnToMainPageButtonClick = (sectionName?: SectionName | null) => {
  const dispatch = useDispatch();
  const { appNavigate } = useAppNavigate();

  const returnToMainPage = () => {
    if (sectionName) {
      dispatch(setSectionToScrollTo(sectionName));
    } else {
      scrollToTop();
    }
    
    appNavigate(PagePath.MainPage);
  }
  return returnToMainPage;
}