import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { DimensionsData } from '../utils/types';
import { SectionName } from '../utils/enums';

interface MainState {
  fetching: boolean;
  blockInterface: boolean;
  dimensionsData: DimensionsData;

  sectionNameToScrollTo: SectionName | null;
  sectionNameToReturnTo: SectionName | null;

  reviewModalText: string | null;
};

const initialState: MainState = {
  fetching: false,
  blockInterface: false,
  dimensionsData: {
    screenSize: {
      width: 1920,
      height: 1080,
    },
    isVerticallyOriented: false,
  },

  sectionNameToScrollTo: null,
  sectionNameToReturnTo: null,

  reviewModalText: null,
};

const settingsSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    // ####################################################################################
    setBlockInterface: (state, { payload }: PayloadAction<boolean>) => {
      state.blockInterface = payload;
    },
    setDimensionsData: (state, { payload }: PayloadAction<DimensionsData>) => {
      state.dimensionsData = payload;
    },
    // ####################################################################################
    setSectionToScrollTo: (state, { payload }: PayloadAction<SectionName | null>) => {
      state.sectionNameToScrollTo = payload;
    },
    setSectionToReturnTo: (state, { payload }: PayloadAction<SectionName | null>) => {
      state.sectionNameToReturnTo = payload;
    },
    // ####################################################################################
    setReviewModalText: (state, { payload }: PayloadAction<string | null>) => {
      state.reviewModalText = payload;
    },
    // ####################################################################################
  },
});

export const {
  setBlockInterface,
  setDimensionsData,

  setSectionToScrollTo,
  setSectionToReturnTo,

  setReviewModalText,
} = settingsSlice.actions;

export default settingsSlice.reducer;
