import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setSectionToScrollTo } from '../../redux/mainReducer';
import { PagePath, SectionName } from '../../utils/enums';
import { useOnReturnToMainPageButtonClick } from '../../utils/hooks/useOnReturnToMainPageButtonClick';
import images from '../../utils/images';

const LogoBlock = ({
  isClickable,
  onClick,
  className,
  width = 66,
  height = 72,
}: {
  isClickable: boolean;
  onClick?: () => void;
  className?: string;
  width?: number;
  height?: number;
}) => {
  const returnToMainPage = useOnReturnToMainPageButtonClick();

  const dispatch = useDispatch();
  const location = useLocation();

  const onLogoClick = () => {
    // when on main page, scroll to "etna app" section
    if (location.pathname === PagePath.MainPage) {
      dispatch(setSectionToScrollTo(SectionName.EtnaApp));
    }

    // otherwise, return to main page
    else {
      returnToMainPage();
    }

    onClick?.();
  }

  return (
    <img
      className={className}
      src={images.logoIconBig}
      alt={isClickable ? 'На главную' : ''}
      style={{ width, height, ...isClickable && { cursor: 'pointer' } }}
      onClick={isClickable ? onLogoClick : undefined}
    />
  );
}

export default LogoBlock;
