import React, { useRef } from 'react';
import Collapsible from 'react-collapsible';
import useComponentDidUpdate from '../../../../utils/hooks/useComponentDidUpdate';
import images from '../../../../utils/images';
import './FaqItemCollapsible.scss';

export interface QuestionItem {
  question: string;
  answer: string | React.ReactNode;
  expanded?: boolean;
}

const FaqItemCollapsible = ({
  faqItem,
  onOpen,
  onClose,
}: {
  faqItem: QuestionItem;
  onOpen: () => void;
  onClose: () => void;
}) => {
  const transitionTime = 0.5; // seconds

  const collapsibleRef = useRef<Collapsible>(null);
  useComponentDidUpdate(() => {
    setExpanded(!!faqItem.expanded);
  }, [faqItem.expanded]);

  const iconImageRef = useRef<HTMLImageElement>(null);
  const iconContainerRef = useRef<HTMLDivElement>(null);
  const faqItemContainerRef = useRef<HTMLDivElement>(null);

  const setExpanded = async (expanded: boolean) => {
    const angle = expanded ? 45 : 0;
    iconImageRef.current!.style.transition = `transform ${transitionTime}s ease-in-out, filter ${transitionTime}s ease-in-out, -webkit-filter ${transitionTime}s ease-in-out`;
    iconImageRef.current!.style.transform = `rotate(${angle}deg)`;
    iconImageRef.current!.style.filter = expanded
      ? 'invert(66%) sepia(100%) saturate(600%) hue-rotate(130deg) brightness(90%) contrast(100%)'
      : 'unset';

    iconContainerRef.current!.style.transition = `background ${transitionTime}s  ease-in-out`;
    iconContainerRef.current!.style.background = expanded ? '#E2F7FF' : '#E0EFF5';

    faqItemContainerRef.current!.style.transition = `background ${transitionTime}s  ease-in-out`;
    faqItemContainerRef.current!.style.background = expanded ? '#F4FBFF' : 'white';
  };

  return (
    <div className="faqBlockItemContainer" ref={faqItemContainerRef}>
      <Collapsible
        open={!!faqItem.expanded}
        ref={collapsibleRef}
        trigger={
          <div className="faqBlockItemHeader">
            <div className="faqBlockItemText">{faqItem.question}</div>
            <div className="faqBlockItemButton" ref={iconContainerRef}>
              <img
                src={images.faqButtonIcon}
                alt="Открыть ответ"
                ref={iconImageRef}
                className={'faqBlockItemButtonIcon'}
              />
            </div>
          </div>
        }
        onOpening={onOpen}
        onClosing={onClose}
        transitionTime={transitionTime * 1000}
      >
        {typeof faqItem.answer === 'string' ? (
          <div className="faqBlockAnswerText">{faqItem.answer}</div>
        ) : (
          faqItem.answer
        )}
      </Collapsible>
    </div>
  );
};

export default FaqItemCollapsible;
