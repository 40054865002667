import { CSSProperties } from 'react';
import { useDispatch } from 'react-redux';
import { setSectionToScrollTo } from '../../redux/mainReducer';
import { SectionName } from '../../utils/enums';

const AppConsultButton = ({
  customTitle,
  className,
  style,
}: {
  customTitle?: string;
  className?: string;
  style?: CSSProperties;
}) => {
  const dispatch = useDispatch();
  const moveToConsultForm = () => {
    dispatch(setSectionToScrollTo(SectionName.Consult));
  };

  return (
    <button className={`appButton ${className || ''}`} style={style} onClick={moveToConsultForm}>
      {customTitle || 'Подать заявку'}
    </button>
  );
};

export default AppConsultButton;