import { useState, ChangeEvent } from "react";
import { validateEmail } from "../helper";

export const useValidationEmail = () => {
  const [emailFieldReceivedInput, setEmailFieldReceivedInput] = useState(false);

  const [userEmail, setUserEmail] = useState('');
  const [userEmailValid, setUserEmailValid] = useState(true);
  const onUserEmailFocus = () => {
    if (!userEmailValid) {
      setUserEmailValid(true);
      setEmailFieldReceivedInput(false);
    }
  };
  const onUserEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setUserEmail(input);
    setEmailFieldReceivedInput(true);
  };
  const onUserEmailCheck = () => {
    const isValid = validateEmail(userEmail);
    setUserEmailValid(isValid);
    return isValid;
  };

  return {
    userEmail,
    setUserEmail,
    userEmailValid,
    emailFieldReceivedInput,
    onUserEmailFocus,
    onUserEmailChange,
    onUserEmailCheck,
  };
}