export const getCensoredEmailString = (email?: string) => {
  if (!email) return '';

  const inputBeforeAtSymbol = email.split('@')[0];
  const emailNameLength = inputBeforeAtSymbol.length;
  if (emailNameLength <= 2) return email;

  const censoredEmail = email[0] + '*'.repeat(Math.min(emailNameLength - 3, 3)) + email.substring(emailNameLength - 2);
  return censoredEmail;
};

export const getShortenedFilename = (filename?: string) => {
  if (!filename) return '';

  // remove and remember extension
  const split = filename.split('.');
  const extension = split.pop();

  const filenameWithoutExtension = split.join('.');

  const length = filenameWithoutExtension.length;
  if (length <= 14) return filename;

  const shortenedFilename =
    filenameWithoutExtension.slice(0, 10) + '...' + filenameWithoutExtension.slice(length - 3) + '.' + extension;

  return shortenedFilename;
};

export const makeFirstLetterUppercaseRestLowercase = (input?: string | null) => {
  if (!input) return '';

  const firstLetter = input[0].toLocaleUpperCase();
  const rest = input.slice(1).toLocaleLowerCase();
  return firstLetter.concat(rest);
}

export const getFileSizeText = (bytes: number) => {
  if (bytes > 1024 * 1024) {
    const sizeInMegabytes = parseFloat((bytes / (1024 * 1024)).toFixed(2));
    return `${sizeInMegabytes} MB`;
  }

  if (bytes > 1024) {
    const sizeInKilobytes = parseFloat((bytes / 1024).toFixed(1));
    return `${sizeInKilobytes} KB`;
  }

  return `${bytes} B`;
}


export const getPriceText = (input: string | number) => {
  const totalPriceText = input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return `${totalPriceText} ₽`;
};

export const getCorrectPluralForPeople = (input: number) => {
  const inputString = input.toString();

  // for numbers that end with 10...19, use this
  const isSingleDigit = inputString.length < 2;
  if (!isSingleDigit && parseInt(inputString[inputString.length - 2]) === 1) {
    return "человек";
  };

  // for other numbers, it depends on last digit
  const lastDigit = parseInt(inputString[inputString.length - 1]);
  switch (lastDigit) {
    case 2: case 3: case 4: 
      return "человека";
    default:
      return "человек";
  };
};

export const getGoalsCountText = (input: number) => {
  const inputString = input.toString();

  // for numbers that end with 10...19, use this
  const isSingleDigit = inputString.length < 2;
  if (!isSingleDigit && parseInt(inputString[inputString.length - 2]) === 1) {
    return `Выбрано ${input} целей`;
  };

  // for other numbers, it depends on last digit
  const lastDigit = parseInt(inputString[inputString.length - 1]);
  switch (lastDigit) {
    case 2: case 3: case 4: 
      return `Выбрано ${input} цели`;
    case 1:
      return `Выбрана 1 цель`;
    default:
      return `Выбрано ${input} целей`;
  };
};

export const getImageUrlForCss = (input: string) => {
  return `url("${input}")`;
}

export const getBuildDateString = () => {
  const buildDateUnixTime = process.env.REACT_APP_BUILD_DATE;
  if (!buildDateUnixTime) return 'undefined';

  const date = new Date(parseInt(buildDateUnixTime));

  const timeZone = 'Europe/Moscow';
  const timeOptions: Intl.DateTimeFormatOptions = {
    timeZone,
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  };
  const dateOptions: Intl.DateTimeFormatOptions = {
    timeZone,
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  };

  const timeString = date.toLocaleString('ru-RU', timeOptions);
  const dateString = date.toLocaleString('ru-RU', dateOptions);

  return `${timeString} ${dateString} ${timeZone}`;
}