import AppConsultButton from '../../../../components/AppConsultButton/AppConsultButton';
import images from '../../../../utils/images';
import MainBannerEarnBlock from './components/MainBannerEarnBlock';
import './MainBanner.scss';

const MainBanner = () => {
  return (
    <>
      <div className="mainBannerContainer">
        <img className="mainBannerPhotos mainBannerHideOnMobile" src={images.mainBannerPhotos} />
        <img className="mainBannerPhotos mainBannerHideOnDesktop" src={images.mainBannerPhotos} />

        <img
          className="mainBannerTagsImage mainBannerHideOnMobile"
          src={images.mainBannerCornerImage}
        />
        <img
          className="mainBannerTagsImageMobile mainBannerHideOnDesktop"
          src={images.mainBannerCornerImageMobile}
        />

        <div className="mainBannerMainInfo">
          <div className="mainBannerMainInfoJoinSection">
            <div className="mainBannerMainInfoJoinSectionTitle">
              {'Становитесь PRO \r\nвместе с EtnaApp'}
            </div>
            <div className="mainBannerMainInfoJoinSectionSlogan">
              {'Лучший способ монетизировать \r\nваш профессионализм!'}
            </div>
            <AppConsultButton className="mainBannerMainInfoJoinSectionButton" />
          </div>

          <div className="mainBannerHideOnMobile">
            <MainBannerEarnBlock />
          </div>
        </div>
      </div>

      <div className="mainBannerEarnBlockContainerMobile mainBannerHideOnDesktop">
        <MainBannerEarnBlock />
      </div>
    </>
  );
}

export default MainBanner