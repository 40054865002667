import { SocialMediaButtonData } from '../../utils/types';

const SocialMediaButton = ({ item, size = 32 }: { item: SocialMediaButtonData; size?: number }) => {
  return (
    <a href={item.href} key={item.href} target="_blank">
      <img
        style={{ width: size, height: size }}
        src={item.icon}
        onMouseOver={(e) => (e.currentTarget.src = item.hoverIcon)}
        onMouseOut={(e) => (e.currentTarget.src = item.icon)}
      />
    </a>
  );
};

export default SocialMediaButton;
