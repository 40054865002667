import RequestCallForm from '../../../../../components/RequestCallForm/RequestCallForm';
import './RequirementsConsultBanner.scss';

const content = {
  title: 'Давайте запланируем консультацию?',
  note: 'Заполните форму сейчас и наши эксперты свяжутся с вами для обсуждения всех деталей!',
}
const RequirementsConsultBanner = () => {
  return (
    <div className="requirementsConsultBannerContainer">
      <div className="requirementsConsultBannerTitleBlock">
        <div className="requirementsConsultBannerTitle">{content.title}</div>
        <div className="requirementsConsultBannerNote">{content.note}</div>
      </div>

      <RequestCallForm
        containerClass="requirementsConsultBannerCallForm"
        inputsContainerClass="requirementsConsultBannerCallFormInputsContainer"
        inputFieldClass="requirementsConsultBannerCallFormInput"
        checkedInputBlockClass="requirementsConsultBannerCallFormPhoneNumberBlock"
        errorTextClass="requirementsConsultBannerCallFormPhoneNumberErrorText"
        callMeButtonClass="requirementsConsultBannerCallFormCallMeButton"
        callMeButtonStyle={{ backgroundColor: '#0EC1C9', color: 'white' }}
        personalDataNoteClass="requirementsConsultBannerCallFormPersonalDataNote"
        inputErrorStyle={{ outline: `2px solid #9B2C2C` }}
      />
    </div>
  );
};

export default RequirementsConsultBanner;