import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { setSectionToScrollTo } from "../../redux/mainReducer";
import { PagePath, SectionName } from "../enums";
import { MenuItem } from "../types";
import { useAppNavigate } from "./useAppNavigate";

export const useHeaderFooterMenuItems = ({
  setHeaderMenuIsOpen,
}: {
  setHeaderMenuIsOpen?: (b: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const { appNavigate, location } = useAppNavigate();

  const moveToSection = useCallback(async (sectionName: SectionName) => {
    dispatch(setSectionToScrollTo(sectionName));
    setHeaderMenuIsOpen?.(false);

    // if a certain page has it's own faq/consult sections, do not navigate back to main page
    // if (
    //   location.pathname === PagePath.SomePageName &&
    //   (sectionName === SectionName.FAQ || sectionName === SectionName.ConsultSection2)
    // ) {
    //   return;
    // }

    if (location.pathname !== PagePath.MainPage) appNavigate(PagePath.MainPage);
  }, [setHeaderMenuIsOpen, appNavigate]);
  
  const menuItems = useMemo(() => {
    const items: MenuItem[] = [
      {
        text: 'Как это работает?',
        onClick: () => moveToSection(SectionName.HowItWorks),
      },
      {
        text: 'Калькулятор',
        onClick: () => moveToSection(SectionName.Calculator),
      },
      {
        text: 'EtnaApp',
        onClick: () => moveToSection(SectionName.EtnaApp),
      },
      {
        text: 'Частые вопросы',
        onClick: () => moveToSection(SectionName.FAQ),
      },
      // {
      //   text: 'Программы и услуги',
      //   onClick: () => appNavigate(PagePath.SearchPage),
      //   disabled: location.pathname === PagePath.SearchPage,
      // },
    ];

    return items;
  }, [location.pathname, moveToSection, appNavigate]);

  return { menuItems, moveToSection };
};