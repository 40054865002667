import { useState } from "react";
import { validatePhoneNumber } from "../helper";

export const useValidationPhone = () => {
  const [phoneFieldReceivedInput, setPhoneFieldReceivedInput] = useState(false);

  const [userPhone, setUserPhone] = useState('');
  const [userPhoneValid, setUserPhoneValid] = useState(true);
  const onUserPhoneCheck = () => {
    const isValid = validatePhoneNumber(userPhone);
    setUserPhoneValid(isValid);
    return isValid;
  };
  
  return {
    userPhone,
    setUserPhone,
    userPhoneValid,
    setUserPhoneValid,
    onUserPhoneCheck,
    phoneFieldReceivedInput,
    setPhoneFieldReceivedInput,
  };
}