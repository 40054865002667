import images from "../../../../utils/images";

export const whatYouGetBlockPeoplePhotos = [
  images.alreadyWithUsPersonPhoto1,
  images.alreadyWithUsPersonPhoto2,
  images.alreadyWithUsPersonPhoto3,
  images.alreadyWithUsPersonPhoto4,
  images.alreadyWithUsPersonPhoto5,
  images.alreadyWithUsPersonPhoto6,
  images.alreadyWithUsPersonPhoto7,
  images.alreadyWithUsPersonPhoto8,
  images.alreadyWithUsPersonPhoto9,
  images.alreadyWithUsPersonPhoto10,
  images.alreadyWithUsPersonPhoto11,
  images.alreadyWithUsPersonPhoto12,
  images.alreadyWithUsPersonPhoto13,
  images.alreadyWithUsPersonPhoto14,
  images.alreadyWithUsPersonPhoto15,
]