import { salesSliderValues } from './CalculatorBlockSalesSlider';
import './CalculatorBlockValuesSliderSalesMark.scss';

const CalculatorBlockValuesSliderSalesMark = ({
  id,
  selectedId,
}: {
  id: number;
  selectedId: number;
}) => {
  const isSelected = id === selectedId;
  const salesValueData = salesSliderValues[id as keyof typeof salesSliderValues];

  return (
    <div
      className="calculatorBlockValuesSliderSalesMarkContainer"
      style={{ color: isSelected ? '#2C5282' : undefined }}
    >
      <div className="calculatorBlockValuesSliderSalesMarkTitle">{salesValueData.title}</div>
      <div className="calculatorBlockValuesSliderSalesMarkDescription">{salesValueData.description}</div>
    </div>
  );
};

export default CalculatorBlockValuesSliderSalesMark;