import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setDimensionsData } from "../../redux/mainReducer";
import { throttle } from "lodash";

export const useTrackScreenSize = () => {
  const dispatch = useDispatch();

  const handleResize = useCallback(() => {
    dispatch(setDimensionsData({
      screenSize: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      isVerticallyOriented: window.innerHeight > window.innerWidth,
    }));
  }, []);
  

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', throttle(handleResize, 200, { trailing: true }));

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
};