export const joinProTeamRequest = async ({
  userCredentials,
  userPhone,
}: {
  userCredentials: string;
  userPhone: string;
}) => {
  const userPhoneFormatted = userPhone.replaceAll(/\D/g, '');

  const requestBody = {
    name: userCredentials,
    email_or_phone: userPhoneFormatted,
    message: 'Заявка на присоединение к PRO-команде',
  };

  // TODO PROD URL
  const url = 'https://dev.feedback.etnamed.ru/feedback?uid=48497eb6c235f14d3754a39cbf7af85a';
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  })
  
  const data = await response.json();

  if (data.send_corp && data.send_user) {
    return true;
  }

  return false;
};