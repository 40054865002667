import WhatYouGetBlockLargeItem from './components/WhatYouGetBlockLargeItem';
import WhatYouGetBlockMobileSlider from './components/WhatYouGetBlockMobileSlider';
import WhatYouGetBlockSmallItems from './components/WhatYouGetBlockSmallItems';
import './WhatYouGetBlock.scss';

const WhatYouGetBlock = () => {
  
  
  return (
    <>
      <div className="whatYouGetBlockContainerOuter">
        <div className="whatYouGetBlockTitle">{'Что вы получите с EtnaApp'}</div>
      
        <div className="whatYouGetBlockContainerInner">
          <div className="whatYouGetBlockSmallItemsContainer">
            {WhatYouGetBlockSmallItems()}
          </div>

          <WhatYouGetBlockLargeItem />
        </div>
      </div>

      <WhatYouGetBlockMobileSlider />
    </>
  );
};

export default WhatYouGetBlock;