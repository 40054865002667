import { useEffect, useState } from 'react';
import PrivacyPolicyTextBlock from '../PrivacyPolicyTextBlock/PrivacyPolicyTextBlock';
import './CookiesConsentNotification.scss';

const consentKey = 'cookieConsent';
const consentValue = 'given';

const CookiesConsentNotification = () => {
  const title = 'Сookie-файлы на сайте';
  const mainText = 'Наш сайт использует куки. Продолжая им пользоваться, вы соглашаетесь на обработку персональных данных в соответствии с ';
  const linkText = 'политикой конфиденциальности';
  const buttonText = 'Принимаю';

  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem(consentKey);
    if (consent !== consentValue) setShowBanner(true);
  }, []);

  const onAcceptClick = () => {
    localStorage.setItem(consentKey, consentValue);
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <div className="cookiesNotificationContainer">
      <div className="cookiesNotificationTitle">{title}</div>
      <PrivacyPolicyTextBlock
        className="cookiesNotificationMainText"
        mainText={mainText}
        linkText={linkText}
      />
      <button className="appButton cookiesNotificationButton" onClick={onAcceptClick}>
        {buttonText}
      </button>
    </div>
  );
};

export default CookiesConsentNotification;
