import './components/CommonStyles.scss';
import './index.scss';

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { PagePath } from './utils/enums';
import { baseUrl } from './utils/requestApi';
import MainPage from './pages/MainPage/MainPage';
import { store } from './redux/store';

// apollo
export const apolloClient = new ApolloClient({
  uri: `${baseUrl}/v1/graphql`,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
});

// routing
const router = createBrowserRouter([
  {
    path: PagePath.MainPage,
    element: <MainPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // in react 18, StrictMode causes all useEffect-s to trigger twice on freshly rendered components
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </ApolloProvider>
  </React.StrictMode>
)