import { useDispatch } from 'react-redux';
import { setSectionToScrollTo } from '../../../../redux/mainReducer';
import { SectionName } from '../../../../utils/enums';
import images from '../../../../utils/images';
import RequirementsConsultBanner from './components/RequirementsConsultBanner';
import './RequirementsForSpecialists.scss';

const RequirementsForSpecialists = () => {
  const dispatch = useDispatch();
  const onArrowClick = () => {
    dispatch(setSectionToScrollTo(SectionName.Consult));
  }
  
  return (
    <div className="requirementsForSpecialistsContainerOuter">
      <div className="requirementsForSpecialistsTitle">
        {'Требования к специалистам'}
      </div>

      <div className="requirementsForSpecialistsFirstSection">
        <div className="requirementsForSpecialistsFirstSectionCard" style={{ flex: 4 }}>
          <div>
            <div className="requirementsForSpecialistsFirstSectionCardTitle">{'Образование'}</div>
            <div className="requirementsForSpecialistsFirstSectionCardText">{'Мы ценим качество и профессионализм. Поэтому нам нужны документы, подтверждающие ваше образование и квалификацию. '}</div>
          </div>

          <div className="requirementsForSpecialistsFirstSectionCardNoteBlock">
            <div className="requirementsForSpecialistsFirstSectionCardNoteBlockIconContainer">
              <img
                className="requirementsForSpecialistsFirstSectionCardNoteBlockIcon"
                src={images.documentIcon}
              />
            </div>
            <div className="requirementsForSpecialistsFirstSectionCardNoteBlockText">
              {'Это может быть диплом, сертификат или другие официальные документы.'}
            </div>
          </div>
        </div>

        <div className="requirementsForSpecialistsFirstSectionCard" style={{ flex: 6 }}>
          <div>
            <div className="requirementsForSpecialistsFirstSectionCardTitle">{'Налоговый статус'}</div>
            <div className="requirementsForSpecialistsFirstSectionCardText">{'Для получения дохода, необходимо зарегистрироваться как налогоплательщик. Предоставьте соответствующие документы, подтверждающие ваш юридический статус. Это может быть ИП, ООО или другая форма регистрации.'}</div>
          </div>

          <div className="requirementsForSpecialistsFirstSectionCardNoteBlock">
            <div className="requirementsForSpecialistsFirstSectionCardNoteBlockIconContainer">
              <img
                className="requirementsForSpecialistsFirstSectionCardNoteBlockIcon" 
                src={images.papersIcon}
              />
            </div>
            <div className="requirementsForSpecialistsFirstSectionNoteBlockInfo">
              <div className="requirementsForSpecialistsFirstSectionCardNoteBlockTitle">{'Нет статуса?'}</div>
              <div className="requirementsForSpecialistsFirstSectionCardNoteBlockText">{'Мы готовы помочь вам, консультируя на каждом этапе.'}</div>
            </div>
          </div>
        </div>

        <button
          onClick={onArrowClick}
          className="requirementsForSpecialistsFirstSectionArrowCard"
        >
          <div className="requirementsForSpecialistsFirstSectionCardArrowContainer">
            <img className="requirementsForSpecialistsFirstSectionCardArrowIcon" src={images.arrowUp} />
          </div>
        </button>
      </div>

      <RequirementsConsultBanner />
    </div>
  );
};

export default RequirementsForSpecialists;