import RequestCallForm from '../../../../components/RequestCallForm/RequestCallForm';
import images from '../../../../utils/images';
import { getImageUrlForCss } from '../../../../utils/stringHelper';
import './ConsultBlock.scss';

const ConsultBlock = ({ blockRef }: { blockRef: React.RefObject<HTMLDivElement> }) => {
  return (
    <div className="consultBlockContainer" ref={blockRef}>
      <div className="consultBlockSection1">
        <div className="consultBlockTitle">{'Остались вопросы?'}</div>
        <div className="consultBlockText">{'Мы готовы ответить на любые ваши вопросы!'}</div>
        <RequestCallForm
          containerClass="consultBlockCallForm"
          inputsContainerClass="consultBlockCallFormInputsContainer"
          inputFieldClass="consultBlockCallFormInput"
          checkedInputBlockClass="consultBlockCallFormPhoneNumberBlock"
          commentInputFieldClass="consultBlockCallFormCommentInput"
          errorTextClass="consultBlockCallFormPhoneNumberErrorText"
          callMeButtonClass="consultBlockCallFormCallMeButton"
          callMeButtonStyle={{ backgroundColor: '#0EC1C9', color: 'white' }}
          personalDataNoteClass="consultBlockCallFormPersonalDataNote"
          inputErrorStyle={{ outline: `2px solid #9B2C2C` }}
        />
      </div>

      <div className="consultBlockSection2">
        <div
          className="consultBlockSection2ImageDesktop"
          style={{ backgroundImage: getImageUrlForCss(images.consultBlockImageDesktop) }}
        />
        <div
          className="consultBlockSection2ImageTablet"
          style={{ backgroundImage: getImageUrlForCss(images.consultBlockImageTablet) }}
        />
        <div
          className="consultBlockSection2ImageMobile"
          style={{ backgroundImage: getImageUrlForCss(images.consultBlockImageMobile) }}
        />
      </div>
    </div>
  );
};

export default ConsultBlock;