export enum PagePath {
  MainPage = '/',
}

export enum SectionName {
  HowItWorks = 'HowItWorks',
  WorkingWithEtnaApp = 'WorkingWithEtnaApp',
  Calculator = 'Calculator',
  EtnaApp = 'EtnaApp',
  FAQ = 'FAQ',
  Consult = 'Consult',
}