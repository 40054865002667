import React, { ReactElement, useEffect, useRef, useState } from 'react';
import './AppTickerTape.scss';

const AppTickerTape = ({
  children,
  speed,
  pause,
  pauseOnHover,
  rtl,
}: {
  children: ReactElement[];
  speed: number;
  pause?: boolean;
  pauseOnHover?: boolean;
  rtl?: boolean;
}) => {
  const tapeRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [tapeWidth, setTapeWidth] = useState(0);
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const [wrapperHeight, setWrapperHeight] = useState(50);

  const [duplicatedItems, setDuplicatedItems] = useState([...children]);

  useEffect(() => {
    console.log(`first`);
  }, [])
  
  // ###################################################################################################
  const [scrollSpeed, setScrollSpeed] = useState(speed);
  const [_, setPosition] = useState(0);

  const pauseAnimation = () => {
    if (pauseOnHover) setScrollSpeed(0);
  };
  const resumeAnimation = () => {
    if (pauseOnHover && !pause) setScrollSpeed(speed);
  };

  // ###################################################################################################
  useEffect(() => {
    setScrollSpeed(pause ? 0 : speed);
  }, [pause])

  // ###################################################################################################
  useEffect(() => {
    const tape = tapeRef.current;
    const wrapper = wrapperRef.current;

    // calculate dimensions
    const updateDimensions = () => {
      setTapeWidth(tape?.scrollWidth || 0);
      setWrapperWidth(wrapper?.offsetWidth || 0);

      // calculate the height of the tallest item
      const heights = Array.from(tape?.children || []).map((child) => (child as HTMLElement).offsetHeight);
      const maxHeight = Math.max(...heights);
      setWrapperHeight(maxHeight);
    };

    // initial calculation
    updateDimensions();

    // handle window resizing for responsiveness
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  useEffect(() => {
    // ensure we duplicate children enough times to cover the wrapper, at least one time
    let numDuplicates = 1;
    if (tapeWidth < wrapperWidth) {
      numDuplicates = Math.ceil(wrapperWidth / tapeWidth) + 1;
    }

    const newItems = [...children];
    for (let i = 0; i < numDuplicates; i++) {
      const updatedItems = children.map((item) => (
        React.cloneElement(item as ReactElement, { key: `${item.key}-${i}` })
      ));
      newItems.push(...updatedItems);
    }
    setDuplicatedItems(newItems);

  }, [tapeWidth, wrapperWidth, children]);

  useEffect(() => {
    const tape = tapeRef.current;
    if (!tape) return;

    let animationFrameId: number;

    const scroll = () => {
      setPosition(prevPosition => {
        let newPosition = rtl 
          ? prevPosition - scrollSpeed
          : prevPosition + scrollSpeed;

        if (rtl && newPosition <= -tapeWidth) {
          newPosition = 0;
        } else if (!rtl && newPosition >= 0) {
          newPosition = -tapeWidth;
        }

        tape.style.transform = `translateX(${newPosition}px)`;
        return newPosition;
      });

      animationFrameId = requestAnimationFrame(scroll);
    };

    if (scrollSpeed > 0) {
      animationFrameId = requestAnimationFrame(scroll);
    }

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [scrollSpeed, tapeWidth]);

  // useEffect(() => {
  //   const tape = tapeRef.current;
  //   if (!tape) return;
  //   let startPosition = 0;
  //   const startTime = performance.now();
  //   const scroll = (time: number) => {
  //     const elapsedTime = time - startTime;
  //     startPosition = -(elapsedTime * scrollSpeed) % tapeWidth;
  //     tape.style.transform = `translateX(${startPosition}px)`;
  //     requestAnimationFrame(scroll);
  //   };
  //   requestAnimationFrame(scroll);
  // }, [tapeWidth]);

  return (
    <div
      className="appTickerTapeWrapper"
      ref={wrapperRef}
      style={{ height: `${wrapperHeight}px` }}
      onMouseEnter={pauseAnimation}
      onMouseLeave={resumeAnimation}
    >
      <div className="appTickerTape" ref={tapeRef}>
        {duplicatedItems}
      </div>
    </div>
  );
};

export default AppTickerTape;