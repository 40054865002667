import { useDispatch } from 'react-redux';
import ModalCloseButton from '../../../../../modals/ModalCloseButton/ModalCloseButton';
import { setReviewModalText } from '../../../../../redux/mainReducer';
import { useAppSelector } from '../../../../../redux/store';
import { useCloseModalOnClickOutside } from '../../../../../utils/hooks/useCloseModalOnClickOutside';
import { useDisableScroll } from '../../../../../utils/hooks/useDisableScroll';
import './ExpandedReviewModal.scss';

const ExpandedReviewModal = () => {
  const reviewModalText = useAppSelector(state => state.main.reviewModalText);
  const modalIsOpen = !!reviewModalText;

  useDisableScroll(modalIsOpen);

  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(setReviewModalText(null));
  }
  const { modalRef } = useCloseModalOnClickOutside({ closeModal });

  if (!reviewModalText) return null;

  return (
    <div className="modalOverlay">
      <div className="expandedReviewModal" ref={modalRef}>
        <ModalCloseButton closeModal={closeModal} />

        {reviewModalText}
      </div>
    </div>
  );
};

export default ExpandedReviewModal;