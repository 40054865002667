import React, { ReactNode } from 'react';
import AppConsultButton from '../../../../../components/AppConsultButton/AppConsultButton';
import images from '../../../../../utils/images';
import { getImageUrlForCss } from '../../../../../utils/stringHelper';
import './WhatYouGetBlockSmallItems.scss';

const WhatYouGetBlockSmallItems = () => {
  return [
    <div key={0}
      className="whatYouGetBlockItem"
      style={{
        justifyContent: 'space-between',
        background: 'linear-gradient(127.77deg, #BCE5FA 7.2%, #D2EAF3 36.52%)',
      }}
    >
      <div>
        <div className="whatYouGetBlockItemTitle">{'Продавайте из любой точки мира '}</div>
        <div className="whatYouGetBlockItemDescription">
          {
            'Наш велнесс-маркетплейс позволяет вам продавать свои продукты независимо от вашего местоположения. С нами ваш бизнес продолжает приносить доход круглосуточно и без выходных.'
          }
        </div>
      </div>
      <AppConsultButton className="whatYouGetBlockConsultButton" />
      <img className="whatYouGetBlockItem1ImageTopRight" src={images.whatYouGetItem1backgroundTopRight} />
      <img
        className="whatYouGetBlockItem1ImageBottomRight"
        src={images.whatYouGetItem1backgroundBottomRight}
      />
    </div>,

    <div key={1}
      className="whatYouGetBlockItem"
      style={{ background: 'linear-gradient(143.74deg, #D2EAF3 7.97%, rgba(224, 239, 245, 0.7) 80.65%)' }}
    >
      <div className="whatYouGetBlockItemTitle">{'Быстрый старт:'}</div>
      <div className="whatYouGetBlockItemAngledPOI" style={{ transform: 'rotate(2deg)', marginTop: 7 }}>
        {'0₽ вложений'}
      </div>
      <div className="whatYouGetBlockItemDescription">
        {
          'Регистрация у нас абсолютно бесплатна. Зачем платить за вход, если можно сразу начать зарабатывать? Просто зарегистрируйтесь, заполните заявку на получение статуса PRO-аккаунта, загрузите свои продукты и начинайте продавать. Никаких скрытых комиссий и подвохов — только чистые доходы от ваших продаж.'
        }
      </div>
    </div>,

    <div key={2}
      className="whatYouGetBlockImageItem3"
      style={{ backgroundImage: getImageUrlForCss(images.whatYouGetItem3image) }}
    />,

    <div key={3}
      className="whatYouGetBlockItem"
      style={{ background: 'linear-gradient(36.58deg, #BCE5FA 7.65%, #D2EAF3 45.55%)' }}
    >
      <div
        className="whatYouGetBlockItemAngledPOI"
        style={{ transform: 'rotate(-3.43deg)', marginBottom: 4 }}
      >
        {'Поддержка'}
      </div>
      <div className="whatYouGetBlockItemTitle">{'по маркетингу'}</div>
      <div className="whatYouGetBlockItemDescription">
        {
          'У вас есть гениальный продукт, но не знаете, как его продать? Наши маркетологи готовы помочь! Мы готовы сделать все возможное, чтобы ваши продукты заметили и захотели купить. От таргетированной рекламы до SEO-оптимизации — мы знаем, как сделать ваш продукт популярным и прибыльным.'
        }
      </div>
    </div>,

    <div key={4}
      className="whatYouGetBlockImageItem5"
      style={{ backgroundImage: getImageUrlForCss(images.whatYouGetItem5image) }}
    />,

    <div key={5}
      className="whatYouGetBlockItem"
      style={{ background: 'linear-gradient(314.75deg, #D2EAF3 -0.05%, rgba(224, 239, 245, 0.7) 76.99%)' }}
    >
      <div className="whatYouGetBlockItemAngledPOI" style={{ transform: 'rotate(5deg)', marginBottom: 2 }}>
        {'Простота'}
      </div>
      <div className="whatYouGetBlockItemTitle">{'использования'}</div>
      <div className="whatYouGetBlockItemDescription">
        {
          'Наша платформа создана для того, чтобы вы могли сосредоточиться на том, что делаете лучше всего — на создании продуктов. Несколько кликов — и ваши товары доступны покупателям. Никаких сложных интерфейсов и запутанных инструкций. Мы сделали все, чтобы ваш опыт работы с нами был удобным.'
        }
      </div>
      <img
        className="whatYouGetBlockItem6ImageBottomRight"
        src={images.whatYouGetItem6backgroundBottomRight}
      />
    </div>,
  ];
};

export default WhatYouGetBlockSmallItems;
