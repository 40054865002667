import Tippy from '@tippyjs/react';
import { ReactElement } from 'react';
import 'tippy.js/dist/tippy.css';
import './CalculatorBlockSliderHandleWithTooltip.scss';

const CalculatorBlockSliderHandleWithTooltip = ({
  handleNode,
  label,
  boundaryElementId,
}: {
  handleNode: ReactElement;
  label: string;
  boundaryElementId: string;
}) => {
  return (
    <Tippy
      className='calculatorBlockSliderHandleWithTooltipHideOnDesktop calculatorSliderTooltip'
      placement='top'
      content={label}
      disabled={false}
      visible={true}
      offset={[0, 12]} // offset [horizontal, vertical]
      popperOptions={{
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              boundary: document.getElementById(boundaryElementId), // constrain to slider area
              padding: 0, // remove extra spacing around the tooltip
            },
          },
          {
            // remove fallback positions to prevent flipping downwards
            name: 'flip',
            options: {
              fallbackPlacements: [], 
            },
          },
        ],
      }}
    >
      {handleNode}
    </Tippy>
  );
};

export default CalculatorBlockSliderHandleWithTooltip;