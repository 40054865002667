import { sleep } from "../helper";

// TODO
export const waitGetFreeConsultRequest = async ({
  userPhone,
}: {
  userPhone: string;
}) => {
  const userPhoneFormatted = userPhone.replaceAll(/\D/g, '');
  await sleep(1000);
  return true;
};