import { ChangeEvent, useState } from 'react';
import AppMaskedPhoneInput from '../../components/AppMaskedPhoneInput/AppMaskedPhoneInput';
import ButtonWithSpinner from '../../components/ButtonWithSpinner/ButtonWithSpinner';
import CommonInlineStyles from '../../components/CommonInlineStyles';
import PrivacyPolicyTextBlock from '../../components/PrivacyPolicyTextBlock/PrivacyPolicyTextBlock';
import { invalidPhoneNumberText } from '../../utils/appConstants';
import { withOpacity } from '../../utils/helper';
import { useCloseModalOnClickOutside } from '../../utils/hooks/useCloseModalOnClickOutside';
import { useDisableScroll } from '../../utils/hooks/useDisableScroll';
import { useModalStateHooks } from '../../utils/hooks/useModalStateHooks';
import { useProcessInputFields } from '../../utils/hooks/useProcessInputFields';
import { useValidationPhone } from '../../utils/hooks/useValidationPhone';
import { joinProTeamRequest } from '../../utils/requests/joinProTeamRequest';
import { colors } from '../../utils/themeConstants';
import ModalCloseButton from '../ModalCloseButton/ModalCloseButton';
import RequestProcessedModal from '../RequestProcessedModal/RequestProcessedModal';
import './JoinProTeamModal.scss';

const JoinProTeamModal = ({
  modalIsOpen,
  closeModal,
}: {
  modalIsOpen: boolean;
  closeModal: () => void;
}) => {
  useDisableScroll(modalIsOpen);
  const { modalRef } = useCloseModalOnClickOutside({ closeModal });
  // ###################################################################################################
  const [userCredentials, setUserCredentials] = useState('');
  const onUserNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserCredentials(e.target.value);
  };

  // ###################################################################################################
  const {
    userPhone,
    setUserPhone,
    userPhoneValid,
    setUserPhoneValid,
    onUserPhoneCheck,
    phoneFieldReceivedInput,
    setPhoneFieldReceivedInput,
  } = useValidationPhone();

  const {
    modalIsOpen: requestProcessedModalIsOpen,
    openModal: openRequestProcessedModal,
    closeModal: closeRequestProcessedModal,
  } = useModalStateHooks();

  const onCloseRequestProcessedModal = () => {
    closeRequestProcessedModal();
    closeModal();
  };

  const { processing, onProcessInputs, requestIsSuccessful } = useProcessInputFields({
    validatorFunctions: [onUserPhoneCheck],
    requestFunction: () => joinProTeamRequest({ userCredentials, userPhone }),
    onSuccess: () => {
      setUserCredentials('');
      setUserPhone('');
    },
    openRequestProcessedModal,
  })

  const disabled = processing || !phoneFieldReceivedInput || !userPhoneValid;
  
  if (!modalIsOpen) return null;

  return (
    <div className="modalOverlay">
      <RequestProcessedModal
        disablingScrollHandledOutside={true}
        modalIsOpen={requestProcessedModalIsOpen}
        closeModal={onCloseRequestProcessedModal}
        requestIsSuccessful={requestIsSuccessful}
      />

      <div className="joinProTeamModalContainer" ref={modalRef}>
        <ModalCloseButton closeModal={closeModal} />

        <div className="joinProTeamModalSection1">
          <div className="joinProTeamModalSection1Title">{'Присоединяйтесь к PRO-команде!'}</div>

          <div className="joinProTeamModalSection1Description">{'Время превращать свои навыки в доход!'}</div>

          <div className="joinProTeamModalSection1InputsContainer">
            <input
              type={'text'}
              autoComplete={'name'}
              maxLength={100}
              placeholder={'Ваше ФИО'}
              value={userCredentials}
              onChange={onUserNameChange}
              className={`inputFieldDynamicOutline joinProTeamModalSection1InputField`}
            />

            <AppMaskedPhoneInput
              userPhone={userPhone}
              setUserPhone={setUserPhone}
              userPhoneValid={userPhoneValid}
              setUserPhoneValid={setUserPhoneValid}
              setFieldReceivedInput={setPhoneFieldReceivedInput}
              className={`inputFieldDynamicOutline joinProTeamModalSection1InputField`}
            />

            {!userPhoneValid && (
              <div className="inputError joinProTeamModalSection1InputError">{invalidPhoneNumberText}</div>
            )}
          </div>

          <ButtonWithSpinner
            onClick={onProcessInputs}
            title={'Стать PRO'}
            fetchingTitle={'Обработка запроса'}
            fetching={processing}
            disabled={disabled}
            style={{
              backgroundColor: undefined,
              ...(disabled
                ? {
                    ...CommonInlineStyles.buttonDisabled,
                    backgroundColor: withOpacity(colors.accentDark, 0.85),
                  }
                : undefined),
            }}
            className="joinProTeamModalSection1Button"
          />

          <PrivacyPolicyTextBlock
            className="joinProTeamModalSection1PrivacyPolicy"
            mainText={'Нажимая на эту кнопку вы даёте свое '}
          />
        </div>

        <div className="joinProTeamModalSection2">
          <div className="joinProTeamModalSection2TagsContainer">
            <div className="joinProTeamModalSection2TagItem1">{'#монетизация'}</div>
            <div className="joinProTeamModalSection2TagItem2">{'#продвижение'}</div>
            <div className="joinProTeamModalSection2TagItem3">{'#удалённаяработа'}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinProTeamModal;