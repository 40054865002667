export const callRequest = async ({
  userName,
  userPhone,
  userEmail,
  userComment,
}: {
  userName: string;
  userPhone: string;
  userEmail: string;
  userComment: string;
}) => {
  const userPhoneFormatted = userPhone.replaceAll(/\D/g, '');

  const requestBody = {
    name: userName,
    email_or_phone: `${userPhoneFormatted} ${userEmail}`,
    message: userComment,
  };

  // TODO PROD URL
  const url = 'https://dev.feedback.etnamed.ru/feedback?uid=48497eb6c235f14d3754a39cbf7af85a';
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  })
  
  const data = await response.json();

  if (data.send_corp && data.send_user) {
    return true;
  }

  return false;
};