import { ChangeEvent, useState } from 'react';
import AppMaskedPhoneInput from '../../components/AppMaskedPhoneInput/AppMaskedPhoneInput';
import ButtonWithSpinner from '../../components/ButtonWithSpinner/ButtonWithSpinner';
import CommonInlineStyles from '../../components/CommonInlineStyles';
import PrivacyPolicyTextBlock from '../../components/PrivacyPolicyTextBlock/PrivacyPolicyTextBlock';
import { companyMail, companyMailHref, invalidEmailText, invalidPhoneNumberText } from '../../utils/appConstants';
import { withOpacity } from '../../utils/helper';
import { useCloseModalOnClickOutside } from '../../utils/hooks/useCloseModalOnClickOutside';
import { useDisableScroll } from '../../utils/hooks/useDisableScroll';
import { useModalStateHooks } from '../../utils/hooks/useModalStateHooks';
import { useProcessInputFields } from '../../utils/hooks/useProcessInputFields';
import { useValidationEmail } from '../../utils/hooks/useValidationEmail';
import { useValidationPhone } from '../../utils/hooks/useValidationPhone';
import { registerForWebinarRequest } from '../../utils/requests/registerForWebinarRequest';
import { colors } from '../../utils/themeConstants';
import ModalCloseButton from '../ModalCloseButton/ModalCloseButton';
import RequestProcessedModal from '../RequestProcessedModal/RequestProcessedModal';
import './WebinarModal.scss';

const WebinarModal = ({
  modalIsOpen,
  closeModal,
}: {
  modalIsOpen: boolean;
  closeModal: () => void;
}) => {
  useDisableScroll(modalIsOpen);
  const { modalRef } = useCloseModalOnClickOutside({ closeModal });
  // ###################################################################################################
  const [userCredentials, setUserCredentials] = useState('');
  const onUserNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserCredentials(e.target.value);
  };

  // ###################################################################################################
  const {
    userEmail,
    setUserEmail,
    userEmailValid,
    emailFieldReceivedInput,
    onUserEmailFocus,
    onUserEmailChange,
    onUserEmailCheck,
  } = useValidationEmail();

  // ###################################################################################################
  const {
    userPhone,
    setUserPhone,
    userPhoneValid,
    setUserPhoneValid,
    onUserPhoneCheck,
    phoneFieldReceivedInput,
    setPhoneFieldReceivedInput,
  } = useValidationPhone();

  const {
    modalIsOpen: requestProcessedModalIsOpen,
    openModal: openRequestProcessedModal,
    closeModal: closeRequestProcessedModal,
  } = useModalStateHooks();

  const onCloseRequestProcessedModal = () => {
    closeRequestProcessedModal();
    closeModal();
  };

  const { processing, onProcessInputs, requestIsSuccessful } = useProcessInputFields({
    validatorFunctions: [onUserPhoneCheck, onUserEmailCheck],
    requestFunction: () => registerForWebinarRequest({ userCredentials, userEmail, userPhone }),
    onSuccess: () => {
      setUserCredentials('');
      setUserEmail('');
      setUserPhone('');
    },
    openRequestProcessedModal,
  });

  const disabled = processing || !emailFieldReceivedInput || !phoneFieldReceivedInput || !userPhoneValid;
  
  if (!modalIsOpen) return null;

  return (
    <div className="modalOverlay">
      <RequestProcessedModal
        disablingScrollHandledOutside={true}
        modalIsOpen={requestProcessedModalIsOpen}
        closeModal={onCloseRequestProcessedModal}
        requestIsSuccessful={requestIsSuccessful}
        successTitle={'Успешная регистрация на бесплатный вебинар!'}
        successMessage={
          <>
            {'Если вы не получили письмо в течение 30 минут проверьте папку «Спам» или напишите нам на почту: '}
            <a className="link opacityOnHover" href={companyMailHref}>
              {companyMail}
            </a>
          </>
        }
      />

      <div className="webinarModalContainer" ref={modalRef}>
        <ModalCloseButton closeModal={closeModal} />

        <div className="webinarModalContainerInner">
          <div className="webinarModalSection1">
            <div className="webinarModalSection1ShortInfoBlock">
              <div className="webinarModalSection1ShortInfoBlockTitle">{'бесплатный вебинар'}</div>
              <div className="webinarModalSection1ShortInfoBlockDate">{'30 июля 2024 г. в 19:00 '}</div>
            </div>

            <div className="webinarModalSection1MainBlock">
              <div className="webinarModalSection1MainBlockTitle">{'Ваши возможности \r\nс EtnaApp'}</div>
              <div className="webinarModalSection1MainBlockDescription">{'Присоединяйтесь к бесплатному онлайн-вебинару от ведущих специалистов. Ссылка на подключение будет отправлена на ваш email'}</div>
            </div>
          </div>

          <div className="webinarModalSection2">
            <input
              type={'text'}
              autoComplete={'name'}
              maxLength={100}
              placeholder={'Ваше ФИО'}
              value={userCredentials}
              onChange={onUserNameChange}
              className={`inputFieldDynamicOutline webinarModalSection2InputField`}
            />

            <input
              type={'text'}
              placeholder={'Ваш e-mail'}
              autoComplete={'email'}
              maxLength={100}
              autoCapitalize={'none'}
              autoCorrect={''}
              value={userEmail}
              onFocus={onUserEmailFocus}
              onChange={onUserEmailChange}
              className={`inputFieldDynamicOutline webinarModalSection2InputField`}
              style={!userEmailValid ? CommonInlineStyles.inputFieldErrorBorder : {}}
            />
            {!userEmailValid && (
              <div className="inputError webinarModalSection2InputError">{invalidEmailText}</div>
            )}

            <AppMaskedPhoneInput
              userPhone={userPhone}
              setUserPhone={setUserPhone}
              userPhoneValid={userPhoneValid}
              setUserPhoneValid={setUserPhoneValid}
              setFieldReceivedInput={setPhoneFieldReceivedInput}
              className={`inputFieldDynamicOutline webinarModalSection2InputField`}
            />

            {!userPhoneValid && (
              <div className="inputError webinarModalSection2InputError">{invalidPhoneNumberText}</div>
            )}

            <ButtonWithSpinner
              onClick={onProcessInputs}
              title={'Участвовать в вебинаре'}
              fetchingTitle={'Обработка запроса'}
              fetching={processing}
              disabled={disabled}
              style={{
                backgroundColor: undefined,
                ...(disabled
                  ? {
                      ...CommonInlineStyles.buttonDisabled,
                      backgroundColor: withOpacity(colors.accentDark, 0.85),
                    }
                  : undefined),
              }}
              className="webinarModalSection2Button"
            />

            <PrivacyPolicyTextBlock
              className="webinarModalSection2PrivacyPolicy"
              mainText={'Нажимая на эту кнопку вы даёте свое '}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebinarModal;