export interface ReviewItemData {
  reviewText: string;
  reviewerCredentials: string;
  likesCount: number;
}

export const reviewsArrayLine1: ReviewItemData[] = [
  {
    reviewText: 'Удобный сервис если хочется повысить доход, но не знаешь как и где искать еще клиентов! Дополнительно искать маркетолога сложно и утомительно, а здесь, за небольшой процент получаешь гарантированных клиентов и даже возможность пассивного дохода, если у вас есть онлайн-продукты. Кто хочет расширить свою клиентскую базу рекомендую!',
    reviewerCredentials: 'Николай П., психолог',
    likesCount: 56,
  },
  {
    reviewText: 'У меня есть онлайн-продукты, и я попробовала их тут разместить. Процент взимается только с готовой продажи, так что риск минимален. Платформа работает через договор, обеспечивает честные выплаты, все прозрачно, что очень важно было для меня. В итоге я получила пассивный доход от продаж, и очень рада результату. Платформа действительно помогает зарабатывать, не беспокоясь о лишних затратах или рисках.',
    reviewerCredentials: 'Кристина Л., нутрициолог',
    likesCount: 182,
  },
  {
    reviewText: 'Хочу выразить благодарность ETNA APP и особенно моему личному менеджеру Сергею! Размещение услуг на платформе оказалось намного проще, чем я ожидал. Менеджер помог мне на каждом этапе: от регистрации до оптимизации моих продуктов, подсказал как лучше их оформить. Я быстро нашел клиентов и смог сосредоточиться только на работе с ними. Также приятно отметить, что выплаты происходят честно и вовремя. Спасибо за отличный сервис!',
    reviewerCredentials: 'Кирилл Я., психотерапевт',
    likesCount: 103,
  },
  {
    reviewText: 'EtnaApp помогает мне не только зарабатывать, но и предоставлять услуги на высшем уровне. Благодаря сервису, я могу эффективно планировать свою работу и почти сразу видеть результаты.',
    reviewerCredentials: 'Екатерина М., нутрициолог',
    likesCount: 163,
  },
  {
    reviewText: 'Платформа привлекает ко мне новых клиентов каждый месяц. Очень удобно, что мне не надо искать клиентов самостоятельно :)',
    reviewerCredentials: 'Анна В., фитнес-инструктор',
    likesCount: 175,
  },
  {
    reviewText: 'За последние несколько месяцев ко мне пришло несколько пар, с которыми мы уже достигли результатов. Спасибо EtnaApp за возможность привлекать всё больше клиентов!))',
    reviewerCredentials: 'Елена К., семейный психолог',
    likesCount: 142,
  },
  {
    reviewText: 'С EtnaApp мой клиентский поток увеличился. Удобный сервис для работы и общения с клиентами. Рекомендую коллегам!',
    reviewerCredentials: 'Дмитрий П., персональный тренер',
    likesCount: 230,
  },
  {
    reviewText: 'Платформа сделала мою работу более эффективной и доходной. Я нашла новых клиентов и улучшила свои навыки благодаря ресурсам, которые предоставляет EtnaApp.',
    reviewerCredentials: 'Марина, нутрициолог',
    likesCount: 135,
  },
  {
    reviewText: 'С платформой я получила больше клиентов, которые заинтересованы в достжени своих фитнес-целей. Ко мне стало обращаться больше людей, желающих управлять своим весом и следить за питанием.',
    reviewerCredentials: 'Евгений М., фитнес-инструктор',
    likesCount: 311,
  },
];

export const reviewsArrayLine2: ReviewItemData[] = [
  {
    reviewText: 'Сейчас реклама стоит довольно дорого, и я решила попробовать разместить свои услуги на ETNA APP. Это оказалось отличным решением! Платформа требует лишь небольшой процент от только готовых сделок, и я не рискую большими затратами. Результаты приятно удивили – смогла быстро привлечь клиентов и заработать без лишних расходов. Очень довольна, что выбрала именно этот сервис!',
    reviewerCredentials: 'Катерина Б., фитнес-тренер',
    likesCount: 131,
  },
  {
    reviewText: 'Понятный и удобный интерфейс, тех поддержка работает оперативно - быстро получилось зарегистрироваться и разместить свои продукты.',
    reviewerCredentials: 'Евгений С., фитнес-инструктор',
    likesCount: 175,
  },
  {
    reviewText: 'Очень удобно что можно комбинировать свои услуги в разные тарифы и создавать пакеты предложений, так я могу продавать как простые продукты в виде только одной консультации, так и комплексно и даже оформлять услуги в подарок. Я заметил, что такие предложения помогают увеличить продажи и привлекают больше клиентов.',
    reviewerCredentials: 'Михаил Б., нутрициолог',
    likesCount: 97,
  },
  {
    reviewText: 'Я забила свой график клиентами благодаря этой платформе! Удобно, что все в одном месте и не нужно тратить время на поиск клиентов. С каждым днем все больше людей интересуются моими услугами, и я уже не успеваю, приходится открывать дополнительное время в своем календаре. Отличный платформа!',
    reviewerCredentials: 'Рената Л., психолог',
    likesCount: 204,
  },
  {
    reviewText: 'Понятный и удобный интерфейс, поддержка работает оперативно.',
    reviewerCredentials: 'Александр Т., психотерапевт',
    likesCount: 96,
  },
  {
    reviewText: 'Работа через платформу помогла мне познакомиться с другими тренерами!) Это сообщество вдохновляет и помогает расти.',
    reviewerCredentials: 'Елена К., семейный психолог',
    likesCount: 109,
  },
  {
    reviewText: '', // Подписывайтесь и следите за нашими обновлениями в социальных сетях:
    reviewerCredentials: '',
    likesCount: 0,
  },
  {
    reviewText: 'Платформа удобна для продажи, а тарифы помогают адаптировать мои услуги под разную покупательскую способность.',
    reviewerCredentials: 'Дария Л., психолог',
    likesCount: 87,
  },
  {
    reviewText: 'Я могу быстро добавлять свои программы питания, используя разные тарифы, что позволяет адаптировать свои услуги под клиента. Такой подход упрощает взаимодейтсвия с клиентами.',
    reviewerCredentials: 'Мария В., диетолог',
    likesCount: 291,
  },
  {
    reviewText: 'Мои занятия стали популярнее и я вижу реальный прогресс у своих подопечных.',
    reviewerCredentials: 'Наталья С., фитнес-инструктор',
    likesCount: 214,
  },
];