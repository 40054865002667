import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { useMemo } from 'react';
import './CalculatorBlockSlider.scss';
import CalculatorBlockSliderHandleWithTooltip from './CalculatorBlockSliderHandleWithTooltip';
import CalculatorBlockValuesSliderSalesMark from './CalculatorBlockValuesSliderSalesMark';

export const salesSliderValues = {
  0: { value:  7.5, title: 'Низкий',  description: '(5-10 продаж в месяц)', mobileTooltip: '5-10 продаж в месяц' },
  1: { value: 30  , title: 'Средний', description: '(10-50 продаж в месяц)', mobileTooltip: '10-50 продаж в месяц' },
  2: { value: 60  , title: 'Высокий', description: '(более 50 продаж в месяц)', mobileTooltip: 'более 50 продаж в месяц' },
}

const CalculatorBlockSalesSlider = ({
  selectedValueIndex,
  setSelectedValueIndex,
}: {
  selectedValueIndex: number;
  setSelectedValueIndex: (n: number) => void;
}) => {
  const marks = useMemo(
    () => ({
      0: <CalculatorBlockValuesSliderSalesMark id={0} selectedId={selectedValueIndex} />,
      1: <CalculatorBlockValuesSliderSalesMark id={1} selectedId={selectedValueIndex} />,
      2: <CalculatorBlockValuesSliderSalesMark id={2} selectedId={selectedValueIndex} />,
    }),
    [selectedValueIndex]
  );

  const boundaryElementId = 'calculationSalesSectionId';

  return (
    <div
      className="calculatorBlockSliderContainer calculatorBlockSalesSliderContainer"
      id={boundaryElementId}
    >
      <div className="calculatorBlockSliderOuterRailing" />
      <Slider
        min={0}
        max={Object.keys(marks).length - 1}
        marks={marks}
        dotStyle={{ display: 'none' }}
        // handleRender={handleRender}
        handleRender={(node, props) => (
          <CalculatorBlockSliderHandleWithTooltip
            handleNode={node}
            label={salesSliderValues[selectedValueIndex as keyof typeof salesSliderValues].mobileTooltip}
            boundaryElementId={boundaryElementId}
          />
        )}
        track={false}
        included={false}
        step={1}
        defaultValue={1}
        activeDotStyle={{ display: 'none' }}
        onChange={(value) => setSelectedValueIndex(value as number)}
      />
    </div>
  );
};

export default CalculatorBlockSalesSlider;