import ButtonWithSpinner from '../../../../components/ButtonWithSpinner/ButtonWithSpinner';
import CommonInlineStyles from '../../../../components/CommonInlineStyles';
import PrivacyPolicyTextBlock from '../../../../components/PrivacyPolicyTextBlock/PrivacyPolicyTextBlock';
import RequestProcessedModal from '../../../../modals/RequestProcessedModal/RequestProcessedModal';
import { appStoreHref, googlePlayHref, invalidEmailText } from '../../../../utils/appConstants';
import { withOpacity } from '../../../../utils/helper';
import { useModalStateHooks } from '../../../../utils/hooks/useModalStateHooks';
import { useProcessInputFields } from '../../../../utils/hooks/useProcessInputFields';
import { useValidationEmail } from '../../../../utils/hooks/useValidationEmail';
import images from '../../../../utils/images';
import { emailSubscribeRequest } from '../../../../utils/requests/emailSubscribeRequest';
import { baseColors, colors } from '../../../../utils/themeConstants';
import './JoinUsBlock.scss';

const JoinUsBlock = ({ blockRef }: { blockRef: React.RefObject<HTMLDivElement> }) => {
  const appStoreButtonText = 'Скачать в\r\n App Store';
  const googlePlayButtonText = 'Доступно в\r\n Google Play';

  const onAppStoreClick = () => {
    // yandexMetrics.downloadIOS();
  }

  const onGooglePlayClick = () => {
    // yandexMetrics.downloadAndroid();
  }
  
  // ###################################################################################################
  const {
    userEmail,
    setUserEmail,
    userEmailValid,
    emailFieldReceivedInput,
    onUserEmailFocus,
    onUserEmailChange,
    onUserEmailCheck,
  } = useValidationEmail();

  // ###################################################################################################
  const {
    modalIsOpen: requestProcessedModalIsOpen,
    openModal: openRequestProcessedModal,
    closeModal: closeRequestProcessedModal,
  } = useModalStateHooks();

  const { processing, onProcessInputs, requestIsSuccessful } = useProcessInputFields({
    validatorFunctions: [onUserEmailCheck],
    requestFunction: () => emailSubscribeRequest({ userEmail }),
    onSuccess: () => {
      setUserEmail('');
    },
    openRequestProcessedModal,
  })

  // ###################################################################################################
  const EmailSubscribeButton = () => {
    const disabled = processing; // || !emailFieldReceivedInput;

    return (
      <ButtonWithSpinner
        onClick={onProcessInputs}
        title={'Подписаться'}
        fetchingTitle={'Обработка запроса'}
        fetching={processing}
        disabled={disabled}
        style={{
          backgroundColor: undefined,
          ...(disabled
            ? {
                ...CommonInlineStyles.buttonDisabled,
                backgroundColor: withOpacity(colors.accentDark as string, 0.65),
                color: baseColors.white,
              }
            : undefined),
        }}
        className="appButton joinUsBlockSection2EmailSubscriptionBlockButton"
        spinnerSize={18}
        spinnerColor={baseColors.white}
      />
    );
  }

  return (
    <div className="joinUsBlockContainer" ref={blockRef}>
      <RequestProcessedModal
        modalIsOpen={requestProcessedModalIsOpen}
        closeModal={closeRequestProcessedModal}
        requestIsSuccessful={requestIsSuccessful}
        successTitle={'Поздравляем!'}
        successMessage={'Теперь вам доступны все самые свежие обновления и эксклюзивные предложения, чтобы всегда быть на шаг впереди.'}
      />

      <div className="joinUsBlockSection1">
        <div className="joinUsBlockSection1MoreBenefitsBlock">
          <div className="joinUsBlockSection1MoreBenefitsBlockTitle">
            {'Больше выгоды \r\nс PRO-аккаунтом в EtnaApp'}
          </div>

          <div className="joinUsBlockSection1MoreBenefitsBlockButtons">
            <a
              className="joinUsBlockSection1MoreBenefitsBlockButton link"
              href={appStoreHref}
              onClick={onAppStoreClick}
              target="_blank"
            >
              <img src={images.appleIcon} alt="" className="joinUsBlockSection1MoreBenefitsBlockButtonIcon" />
              {appStoreButtonText}
            </a>
            <a
              className="joinUsBlockSection1MoreBenefitsBlockButton link"
              href={googlePlayHref}
              onClick={onGooglePlayClick}
              target="_blank"
            >
              <img
                src={images.googlePlayIcon}
                alt=""
                className="joinUsBlockSection1MoreBenefitsBlockButtonIcon"
              />
              {googlePlayButtonText}
            </a>
          </div>
        </div>

        <img className="joinUsBlockSection1PhoneImage" src={images.joinUsPhoneDesktop} />
        <img className="joinUsBlockSection1PhoneImageMobile" src={images.joinUsPhoneMobile} />

        <div className="joinUsBlockQRCodeSection">
          <img className="joinUsBlockQRCodeImage" src={images.joinUsQR} />
          <div className="joinUsBlockQRCodeSectionText">{'Наведите камеру на QR-код, чтобы скачать'}</div>
        </div>
      </div>

      <div className="joinUsBlockSection2">
        <div className="joinUsBlockSection2EmailSubscriptionBlock">
          <div className="joinUsBlockSection2EmailSubscriptionBlockTitle">
            {'Будьте в курсе о свежих обновлениях и самых выгодных предложениях'}
          </div>
          <div className="joinUsBlockSection2EmailSubscriptionBlockInputContainer">
            <input
              type={'text'}
              placeholder={'Ваш e-mail'}
              autoComplete={'email'}
              maxLength={100}
              autoCapitalize={'none'}
              autoCorrect={''}
              value={userEmail}
              onFocus={onUserEmailFocus}
              onChange={onUserEmailChange}
              className="inputFieldDynamicOutline joinUsBlockSection2EmailSubscriptionBlockInput"
              style={!userEmailValid ? CommonInlineStyles.inputFieldErrorBorder : {}}
            />
            {!userEmailValid && (
              <div className="joinUsBlockSection2EmailSubscriptionBlockError">{invalidEmailText}</div>
            )}
          </div>
          <EmailSubscribeButton />
        </div>
        <PrivacyPolicyTextBlock
          className="joinUsBlockSection2PrivacyPolicy"
          mainText="Нажимая на “Подписаться”, я соглашаюсь получать рекламные и иные маркетинговые сообщения от ООО “Этнамед” на условиях "
          linkText="Политики конфиденциальности и Пользовательского соглашения"
        />
      </div>
    </div>
  );
};

export default JoinUsBlock;