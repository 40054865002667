import { useEffect } from "react";

const modalWasShownKey = 'modalWasShown';
const modalWasShownValue = 'true';

export const useShowModalBeforeClosingWebsite = ({ openModal }: { openModal: () => void }) => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const modalWasShown = localStorage.getItem(modalWasShownKey);
      if (modalWasShown !== modalWasShownValue) {
        localStorage.setItem(modalWasShownKey, modalWasShownValue);
        event.preventDefault();
        openModal();
      }

      // const message = "Are you sure you want to leave this page?";
      // event.returnValue = message; // For Chrome, Firefox, IE
      // return message; // For Safari
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
};