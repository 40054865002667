import images from '../../utils/images';
import './ModalCloseButton.scss';

const ModalCloseButton = ({ closeModal }: { closeModal: () => void }) => {
  return (
    <button className="modalCloseButton" onClick={closeModal}>
      <img className="modalCloseButtonIcon" src={images.closeModalCrossIcon} />
    </button>
  );
};

export default ModalCloseButton;