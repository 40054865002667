import { colors } from '../../utils/themeConstants';
import './AppSpinner.scss';

const AppSpinner = ({
  color = colors.accent,
  size = 30,
  thickness = 2,
  quarters = 3,
  spinTime = 1,
}: {
  color?: string;
  size?: number;
  thickness?: number;
  quarters?: number;
  spinTime?: number;
}) => {
  const circleThickness = thickness || ((size / 10) - 1);

  const radius = (size - circleThickness) / 2;
  const circumference = 2 * Math.PI * radius;
  const arcLength = (quarters / 4) * circumference;
  const offset = circumference - arcLength;

  return (
    <div className="spinnerContainer" style={{ width: size, height: size }}>
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        <circle
          className="spinnerCircle"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          stroke={color}
          strokeWidth={circleThickness}
          strokeDasharray={`${arcLength} ${circumference}`}
          strokeDashoffset={-offset / 2}
          style={{ animationDuration: `${spinTime}s`, }}
        />
      </svg>
    </div>
  );
};

export default AppSpinner;
