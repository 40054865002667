import { useState } from 'react';
import AppTooltip from '../../../../../components/AppTooltip/AppTooltip';
import images from '../../../../../utils/images';
import './WhatYouGetBlockThisIsAPlaceForYou.scss';

const WhatYouGetBlockThisIsAPlaceForYou = () => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <button className="whatYouGetBlockMorePeopleItem" onClick={() => setShowTooltip(!showTooltip)}>
      <img className="whatYouGetBlockMorePeoplePlusIcon" src={images.plusAccent} />

      {showTooltip && (
        <AppTooltip style={{ marginBottom: 16 }}>
          {'А это место для вас'}
          <img className="whatYouGetBlockThisIsAPlaceForYouIcon" src={images.handshake} />
        </AppTooltip>
      )}
    </button>
  );
};

export default WhatYouGetBlockThisIsAPlaceForYou;