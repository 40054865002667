// pro landing
import logoIconBig from '../images/pro-landing-images/logoIconBig.png';
import arrowUp from '../images/pro-landing-images/arrowUp.png';

// pro landing main banner
import mainBannerPhotos from '../images/pro-landing-images/1.mainBanner/mainBannerPhotos.png';
import mainBannerPhotosMobile from '../images/pro-landing-images/1.mainBanner/mainBannerPhotosMobile.png';
import mainBannerCornerImage from '../images/pro-landing-images/1.mainBanner/mainBannerCornerImage.png';
import mainBannerCornerImageMobile from '../images/pro-landing-images/1.mainBanner/mainBannerCornerImageMobile.png';
import mainBannerBackgroundDesktop from '../images/pro-landing-images/1.mainBanner/mainBannerBackgroundDesktop.png';
import mainBannerBackgroundTablet from '../images/pro-landing-images/1.mainBanner/mainBannerBackgroundTablet.png';
import mainBannerBackgroundMobile from '../images/pro-landing-images/1.mainBanner/mainBannerBackgroundMobile.png';

// pro landing main banner cards
import mainBannerCard1 from '../images/pro-landing-images/1.1.mainBannerCards/mainBannerCard1.jpg';
import mainBannerCard2 from '../images/pro-landing-images/1.1.mainBannerCards/mainBannerCard2.jpg';
import mainBannerCard3 from '../images/pro-landing-images/1.1.mainBannerCards/mainBannerCard3.jpg';

// pro landing main banner cards
import playCursorIcon from '../images/pro-landing-images/3.VideoPlayer/playCursorIcon.png';
import pauseCursorIcon from '../images/pro-landing-images/3.VideoPlayer/pauseCursorIcon.png';

// pro landing what you get block
import whatYouGetItem1backgroundBottomRight from '../images/pro-landing-images/4.WhatYouGetBlock/whatYouGetItem1backgroundBottomRight.png';
import whatYouGetItem1backgroundTopRight from '../images/pro-landing-images/4.WhatYouGetBlock/whatYouGetItem1backgroundTopRight.png';
import whatYouGetItem6backgroundBottomRight from '../images/pro-landing-images/4.WhatYouGetBlock/whatYouGetItem6backgroundBottomRight.png';
import whatYouGetItem3image from '../images/pro-landing-images/4.WhatYouGetBlock/whatYouGetItem3image.jpg';
import whatYouGetItem5image from '../images/pro-landing-images/4.WhatYouGetBlock/whatYouGetItem5image.jpg';
import whatYouGetItem7backgroundDesktopRight from '../images/pro-landing-images/4.WhatYouGetBlock/whatYouGetItem7backgroundDesktopRight.png';
import whatYouGetTriangleForPopup from '../images/pro-landing-images/4.WhatYouGetBlock/whatYouGetTriangleForPopup.png';
import plusAccent from '../images/pro-landing-images/plusAccent.png';

// pro landing requirements for specialists block
import documentIcon from '../images/pro-landing-images/5.RequirementsForSpecialists/documentIcon.png';
import papersIcon from '../images/pro-landing-images/5.RequirementsForSpecialists/papersIcon.png';
import alreadyWithUsPersonPhoto1 from '../images/pro-landing-images/4.WhatYouGetBlock/peoplePhotos/1.png';
import alreadyWithUsPersonPhoto2 from '../images/pro-landing-images/4.WhatYouGetBlock/peoplePhotos/2.png';
import alreadyWithUsPersonPhoto3 from '../images/pro-landing-images/4.WhatYouGetBlock/peoplePhotos/3.png';
import alreadyWithUsPersonPhoto4 from '../images/pro-landing-images/4.WhatYouGetBlock/peoplePhotos/4.png';
import alreadyWithUsPersonPhoto5 from '../images/pro-landing-images/4.WhatYouGetBlock/peoplePhotos/5.png';
import alreadyWithUsPersonPhoto6 from '../images/pro-landing-images/4.WhatYouGetBlock/peoplePhotos/6.png';
import alreadyWithUsPersonPhoto7 from '../images/pro-landing-images/4.WhatYouGetBlock/peoplePhotos/7.png';
import alreadyWithUsPersonPhoto8 from '../images/pro-landing-images/4.WhatYouGetBlock/peoplePhotos/8.png';
import alreadyWithUsPersonPhoto9 from '../images/pro-landing-images/4.WhatYouGetBlock/peoplePhotos/9.png';
import alreadyWithUsPersonPhoto10 from '../images/pro-landing-images/4.WhatYouGetBlock/peoplePhotos/10.png';
import alreadyWithUsPersonPhoto11 from '../images/pro-landing-images/4.WhatYouGetBlock/peoplePhotos/11.png';
import alreadyWithUsPersonPhoto12 from '../images/pro-landing-images/4.WhatYouGetBlock/peoplePhotos/12.png';
import alreadyWithUsPersonPhoto13 from '../images/pro-landing-images/4.WhatYouGetBlock/peoplePhotos/13.png';
import alreadyWithUsPersonPhoto14 from '../images/pro-landing-images/4.WhatYouGetBlock/peoplePhotos/14.png';
import alreadyWithUsPersonPhoto15 from '../images/pro-landing-images/4.WhatYouGetBlock/peoplePhotos/15.png';

// pro landing how it works
import howItWorksCardImage1 from '../images/pro-landing-images/6.HowItWorks/howItWorksCardImage1.png';
import howItWorksCardImage2 from '../images/pro-landing-images/6.HowItWorks/howItWorksCardImage2.png';
import howItWorksCardImage3 from '../images/pro-landing-images/6.HowItWorks/howItWorksCardImage3.png';
import howItWorksCardImage4 from '../images/pro-landing-images/6.HowItWorks/howItWorksCardImage4.png';
import howItWorksCardImage5 from '../images/pro-landing-images/6.HowItWorks/howItWorksCardImage5.png';
import howItWorksBigIllust from '../images/pro-landing-images/6.HowItWorks/howItWorksBigIllust.jpg';
import howItWorksSmallIllust from '../images/pro-landing-images/6.HowItWorks/howItWorksSmallIllust.jpg';

// pro landing working with etna app
import workingWithEtnaAppCard1background from '../images/pro-landing-images/7.WorkingWithEtnaApp/card1background.png';
import workingWithEtnaAppCard2background from '../images/pro-landing-images/7.WorkingWithEtnaApp/card2background.png';
import workingWithEtnaAppCard3background1 from '../images/pro-landing-images/7.WorkingWithEtnaApp/card3background1.jpg';
import workingWithEtnaAppCard3background2 from '../images/pro-landing-images/7.WorkingWithEtnaApp/card3background2.jpg';
import workingWithEtnaAppCard3background1mobile from '../images/pro-landing-images/7.WorkingWithEtnaApp/card3background1mobile.jpg';
import workingWithEtnaAppCard3background2mobile from '../images/pro-landing-images/7.WorkingWithEtnaApp/card3background2mobile.jpg';

// pro landing reviews
import heart from '../images/pro-landing-images/9.ReviewsBlock/heart.png';
import reviewsVkIcon from '../images/pro-landing-images/9.ReviewsBlock/reviewsVkIcon.png';
import reviewsTgIcon from '../images/pro-landing-images/9.ReviewsBlock/reviewsTgIcon.png';

// pro landing join us block
import joinUsPhoneDesktop from '../images/pro-landing-images/10.JoinUsBlock/joinUsPhoneDesktop.png';
import joinUsPhoneMobile from '../images/pro-landing-images/10.JoinUsBlock/joinUsPhoneMobile.png';
import joinUsQR from '../images/pro-landing-images/10.JoinUsBlock/joinUsQR.png';

// pro landing consult block
import consultBlockImageDesktop from '../images/pro-landing-images/12.ConsultBlock/consultBlockImageDesktop.png';
import consultBlockImageTablet from '../images/pro-landing-images/12.ConsultBlock/consultBlockImageTablet.png';
import consultBlockImageMobile from '../images/pro-landing-images/12.ConsultBlock/consultBlockImageMobile.png';

// pro landing modals
import closeModalCrossIcon from '../images/pro-landing-images/modals/closeModalCrossIcon.png';
import modalSuccess from '../images/pro-landing-images/modals/modalSuccess.png';
import modalFailure from '../images/pro-landing-images/modals/modalFailure.png';
import waitGetFreeConsultClock from '../images/pro-landing-images/modals/waitGetFreeConsultClock.png';
import waitGetFreeConsultImageDesktop from '../images/pro-landing-images/modals/waitGetFreeConsultImageDesktop.png';
import waitGetFreeConsultImageTablet from '../images/pro-landing-images/modals/waitGetFreeConsultImageTablet.png';
import waitGetFreeConsultImageMobile from '../images/pro-landing-images/modals/waitGetFreeConsultImageMobile.png';
import joinProTeamModalImageDesktop from '../images/pro-landing-images/modals/joinProTeamModalImageDesktop.png';
import joinProTeamModalImageTablet from '../images/pro-landing-images/modals/joinProTeamModalImageTablet.png';
import joinProTeamModalImageMobile from '../images/pro-landing-images/modals/joinProTeamModalImageMobile.png';

// misc images
import logoIcon from '../images/logoIcon.png';
import checkboxEmpty from '../images/checkboxEmpty.png';
import checkboxChecked from '../images/checkboxChecked.png';
import appleIcon from '../images/appleIcon.png';
import googlePlayIcon from '../images/googlePlayIcon.png';
import telegramIcon from '../images/iconTelegram.png';
import telegramIconHover from '../images/iconTelegramHover.png';
import vkIcon from '../images/iconVk.png';
import vkIconHover from '../images/iconVkHover.png';

// modals
import modalError from '../images/modalError.png';
import modalCross from '../images/modalCross.png';
import purchaseModalCreditCards from '../images/purchaseModalCreditCards.png';

// reviews
import reviewsCarouselArrow from '../images/reviewsCarouselArrow.png';
import reviewer1 from '../images/reviewers/reviewer1.png';
import reviewer2 from '../images/reviewers/reviewer2.png';
import reviewer3 from '../images/reviewers/reviewer3.png';
import reviewer4 from '../images/reviewers/reviewer4.png';
import reviewBackground from '../images/reviewers/reviewBackground.png';
import reviewBackgroundMobile from '../images/reviewers/reviewBackgroundMobile.png';

// experts and mentors
import expertsAndMentorsImage1 from '../images/expertsAndMentorsImage1.png';
import expertsAndMentorsImage2 from '../images/expertsAndMentorsImage2.png';
import expertsAndMentorsImage3 from '../images/expertsAndMentorsImage3.png';

// main page blocks
import headerMobileMenuIcon from '../images/headerMobileMenuButtonIcon.png';
import headerMobileMenuIconClose from '../images/headerMobileMenuButtonClose.png';

import mainBannerBg1 from '../images/mainBannerBg1.png';
import mainBannerBg2 from '../images/mainBannerBg2.png';

// offer v2 main banner
import mainBannerBlockHeader from '../images/mainBannerBlockHeader.png';

import servicesLine1 from '../images/servicesLine1.png';
import servicesGiftIcon from '../images/servicesGiftIcon.png';

import consult1Background from '../images/consultEtnamedLogo.svg';
import consult2Background from '../images/consult2Background.png';
import consult2RightImageBlock from '../images/consult2RightImageBlock.png';
import consult23Cards from '../images/consult23Cards.png';

import joinUsPhoneImage from '../images/joinUsPhoneImage.png';
import joinUsPhoneImageMobile from '../images/joinUsPhoneImageMobile.png';
import joinUsAppEvents from '../images/alreadyInAppEvents.png';

import faqRectangleBg from '../images/faqRectangleBg.png';
import faqButtonIcon from '../images/faqButtonIcon.png';

// emojis:
import handshake from '../images/emojis/handshake.png';
import strength from '../images/emojis/strength.png';
import run from '../images/emojis/run.png';
import mail from '../images/emojis/mail.png';
import explosion from '../images/emojis/explosion.png';

export default {
  // pro landing
  logoIconBig,
  arrowUp,

  // pro landing main banner
  mainBannerPhotos,
  mainBannerPhotosMobile,
  mainBannerCornerImage,
  mainBannerCornerImageMobile,
  mainBannerBackgroundDesktop,
  mainBannerBackgroundTablet,
  mainBannerBackgroundMobile,

  // pro landing main banner cards
  mainBannerCard1,
  mainBannerCard2,
  mainBannerCard3,
  
  // pro landing video player
  playCursorIcon,
  pauseCursorIcon,

  // pro landing what you get block
  whatYouGetItem1backgroundBottomRight,
  whatYouGetItem1backgroundTopRight,
  whatYouGetItem6backgroundBottomRight,
  whatYouGetItem3image,
  whatYouGetItem5image,
  whatYouGetItem7backgroundDesktopRight,
  whatYouGetTriangleForPopup,
  plusAccent,

  // pro landing requirements for specialists block
  documentIcon,
  papersIcon,
  alreadyWithUsPersonPhoto1,
  alreadyWithUsPersonPhoto2,
  alreadyWithUsPersonPhoto3,
  alreadyWithUsPersonPhoto4,
  alreadyWithUsPersonPhoto5,
  alreadyWithUsPersonPhoto6,
  alreadyWithUsPersonPhoto7,
  alreadyWithUsPersonPhoto8,
  alreadyWithUsPersonPhoto9,
  alreadyWithUsPersonPhoto10,
  alreadyWithUsPersonPhoto11,
  alreadyWithUsPersonPhoto12,
  alreadyWithUsPersonPhoto13,
  alreadyWithUsPersonPhoto14,
  alreadyWithUsPersonPhoto15,

  // pro landing how it works
  howItWorksCardImage1,
  howItWorksCardImage2,
  howItWorksCardImage3,
  howItWorksCardImage4,
  howItWorksCardImage5,
  howItWorksBigIllust,
  howItWorksSmallIllust,

  // pro landing working with etna app
  workingWithEtnaAppCard1background,
  workingWithEtnaAppCard2background,
  workingWithEtnaAppCard3background1,
  workingWithEtnaAppCard3background2,
  workingWithEtnaAppCard3background1mobile,
  workingWithEtnaAppCard3background2mobile,

  // pro landing reviews
  heart,
  reviewsVkIcon,
  reviewsTgIcon,
  
  // pro landing join us
  joinUsPhoneDesktop,
  joinUsPhoneMobile,
  joinUsQR,

  // pro landing consult block
  consultBlockImageDesktop,
  consultBlockImageTablet,
  consultBlockImageMobile,

  // pro landing modals
  closeModalCrossIcon,
  modalSuccess,
  modalFailure,
  waitGetFreeConsultClock,
  waitGetFreeConsultImageDesktop,
  waitGetFreeConsultImageTablet,
  waitGetFreeConsultImageMobile,
  joinProTeamModalImageDesktop,
  joinProTeamModalImageTablet,
  joinProTeamModalImageMobile,

  // misc images
  logoIcon,

  checkboxEmpty,
  checkboxChecked,
  appleIcon,
  googlePlayIcon,
  telegramIcon,
  telegramIconHover,
  vkIcon,
  vkIconHover,

  reviewsCarouselArrow,
  reviewer1,
  reviewer2,
  reviewer3,
  reviewer4,
  reviewBackground,
  reviewBackgroundMobile,

  // modals
  modalError,
  modalCross,
  purchaseModalCreditCards,

  // experts and mentors
  expertsAndMentorsImage1,
  expertsAndMentorsImage2,
  expertsAndMentorsImage3,

  // main page blocks
  headerMobileMenuIcon,
  headerMobileMenuIconClose,
  mainBannerBg1,
  mainBannerBg2,
  mainBannerBlockHeader,
  
  servicesLine1,
  servicesGiftIcon,

  consult1Background,
  consult2Background,
  consult2RightImageBlock,
  consult23Cards,

  joinUsPhoneImage,
  joinUsPhoneImageMobile,
  joinUsAppEvents,

  faqRectangleBg,
  faqButtonIcon,

  // emojis
  handshake,
  explosion,
  strength,
  run,
  mail,
};