import { sleep } from "../helper";

// TODO
export const emailSubscribeRequest = async ({
  userEmail,
}: {
  userEmail: string;
}) => {
  await sleep(1000);
  return true;
};