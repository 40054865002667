import AppConsultButton from '../../../../components/AppConsultButton/AppConsultButton';
import JoinProTeamModal from '../../../../modals/JoinProTeamModal/JoinProTeamModal';
import WebinarModal from '../../../../modals/WebinarModal/WebinarModal';
import { useModalStateHooks } from '../../../../utils/hooks/useModalStateHooks';
import images from '../../../../utils/images';
import { getImageUrlForCss } from '../../../../utils/stringHelper';
import './WorkingWithEtnaApp.scss';

const WorkingWithEtnaApp = ({ blockRef }: { blockRef: React.RefObject<HTMLDivElement> }) => {
  const {
    modalIsOpen: joinProTeamModalIsOpen,
    openModal: openJoinProTeamModal,
    closeModal: closeJoinProTeamModal,
  } = useModalStateHooks();

  const {
    modalIsOpen: webinarModalIsOpen,
    openModal: openWebinarTeamModal,
    closeModal: closeWebinarTeamModal,
  } = useModalStateHooks();
  
  return (
    <div className="workingWithEtnaAppContainer" ref={blockRef}>
      <JoinProTeamModal modalIsOpen={joinProTeamModalIsOpen} closeModal={closeJoinProTeamModal} />
      <WebinarModal modalIsOpen={webinarModalIsOpen} closeModal={closeWebinarTeamModal} />

      <div className="workingWithEtnaAppTitle">{'Работа с ETNA APP — это каждодневые вызовы и рост'}</div>

      {/* desktop & tablet version */}
      <div className="workingWithEtnaAppCards12Container">
        {/* card 1 */}
        <div className="workingWithEtnaAppCard1Container">
          <div className="workingWithEtnaAppCard1Left">
            <div className="workingWithEtnaAppCardTitle">{'Проводим вебинары, участвуем в конференциях'}</div>

            <div className="workingWithEtnaAppCard1LeftWebinarDataAndButton">
              <div className="workingWithEtnaAppCardWebinarDate">{'30 июля 2024 г. в 19:00'}</div>
              <div className="workingWithEtnaAppCardTitle">{'«Ваши возможности с EtnaApp»'}</div>
              <button className="appButton workingWithEtnaAppCardButton" onClick={openWebinarTeamModal}>
                {'Принять участие'}
              </button>
            </div>
          </div>
          <div className="workingWithEtnaAppCard1Right">
            <div className="workingWithEtnaAppCardRightCircle" />
            <img
              className="workingWithEtnaAppCardRightImage"
              src={images.workingWithEtnaAppCard1background}
            />
            <div className="workingWithEtnaAppCardRightNote">{'Бесплатный вебинар '}</div>
          </div>
        </div>

        {/* card 2 */}
        <div className="workingWithEtnaAppCard2Container">
          <div className="workingWithEtnaAppCard2Left">
            <div className="workingWithEtnaAppCardTitle">
              {'Работаем с ведущими специалистами и профессионалами'}
            </div>
            <button className="appButton workingWithEtnaAppCardButton" onClick={openJoinProTeamModal}>
              {'Хочу в команду'}
            </button>
          </div>
          <div className="workingWithEtnaAppCard2Right">
            <div className="workingWithEtnaAppCardRightCircle" />
            <img
              className="workingWithEtnaAppCardRightImage"
              style={{ right: 20 }}
              src={images.workingWithEtnaAppCard2background}
            />
          </div>
        </div>
      </div>

      {/* card 3 */}
      <div className="workingWithEtnaAppCard3Container">
        <div
          className="workingWithEtnaAppCard3Left"
          style={{ backgroundImage: getImageUrlForCss(images.workingWithEtnaAppCard3background1) }}
        >
          <div className="workingWithEtnaAppCardTitle">{'Обучаем и развиваем новые навыки'}</div>
          <AppConsultButton />
        </div>
        <div
          className="workingWithEtnaAppCard3Right"
          style={{ backgroundImage: getImageUrlForCss(images.workingWithEtnaAppCard3background2) }}
        >
          <div className="workingWithEtnaAppCardRightCircle" style={{ backgroundColor: 'white' }} />
        </div>
      </div>


      {/* mobile version */}
      <div className="workingWithEtnaAppMobile">
        <div className="workingWithEtnaAppCardMobile">
          {'Проводим вебинары, участвуем в конференциях'}
          <div className="workingWithEtnaAppCardRightCircle" />
        </div>

        <div className="workingWithEtnaAppCard1LeftWebinarDataAndButtonMobile">
          <div className="workingWithEtnaAppCard1LeftWebinarDataAndButtonMobileTopSection">
            <div className="workingWithEtnaAppCard1LeftWebinarDataMobile">
              <div className="workingWithEtnaAppCardWebinarDate">{'30 июля 2024 г. в 19:00'}</div>
              {'«Ваши возможности\r\nс EtnaApp»'}
            </div>
            <div className="workingWithEtnaAppCardRightNote">{'Бесплатный\r\nвебинар '}</div>
          </div>
          <button className="appButton workingWithEtnaAppCardButtonMobile" onClick={openWebinarTeamModal}>
            {'Принять участие'}
          </button>
        </div>

        <div className="workingWithEtnaAppImageCardContainerMobile">
          <img
            className="workingWithEtnaAppImageCardContainerMobileLeft"
            src={images.workingWithEtnaAppCard3background1mobile}
          />
          <img
            className="workingWithEtnaAppImageCardContainerMobileRight"
            src={images.workingWithEtnaAppCard3background2mobile}
          />
        </div>


        <div className="workingWithEtnaAppCardMobile">
          {'Работаем с ведущими специалистами и профессионалами'}
          <div className="workingWithEtnaAppCardRightCircle" />
        </div>

        <div className="workingWithEtnaAppCardMobile">
          {'Обучаем и развиваем новые навыки'}
          <div className="workingWithEtnaAppCardRightCircle" />
        </div>

        <button className="appButton workingWithEtnaAppCardButtonMobile" onClick={openJoinProTeamModal}>
          {'Хочу в команду'}
        </button>
      </div>
    </div>
  );
};

export default WorkingWithEtnaApp;