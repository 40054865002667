import { useEffect } from "react";

export const useDisableScroll = (modalIsOpen: boolean) => {
  const scrollbarWidth = window.innerWidth - document.body.offsetWidth;

  useEffect(() => {
    const defaultOverflowValue = 'unset';
    document.body.style.overflowY = modalIsOpen ? 'hidden' : defaultOverflowValue;
    document.documentElement.style.overflowY = modalIsOpen ? 'hidden' : defaultOverflowValue;

    const appContainer = document.getElementsByClassName('appContainer')[0] as HTMLElement;
    const targetComponent = appContainer;
    
    if (targetComponent) {
      targetComponent.style.paddingRight = modalIsOpen ? `${scrollbarWidth * 2}px` : '0px';
      targetComponent.style.marginRight = modalIsOpen ? `-${scrollbarWidth}px` : '0px';
    }

    return () => {
      document.body.style.overflowY = defaultOverflowValue;
      document.documentElement.style.overflowY = defaultOverflowValue;

      if (targetComponent) {
        targetComponent.style.paddingRight = '0px';
        targetComponent.style.marginRight = '0px';
      }
    };
  }, [modalIsOpen]);
};