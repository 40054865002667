import { useDispatch } from 'react-redux';
import { setSectionToScrollTo } from '../../redux/mainReducer';
import { SectionName } from '../../utils/enums';
import AppTickerTape from '../AppTickerTape/AppTickerTape';
import './WebinarTape.scss';

const WebinarTapeItem = () => {
  const dispatch = useDispatch();
  const scrollToWebinarSection = () => {
    dispatch(setSectionToScrollTo(SectionName.WorkingWithEtnaApp));
  };

  return (
    <button className="webinarTapeItem" onClick={scrollToWebinarSection}>
      {'Бесплатный вебинар «…» 30 июля 2024 года'}
    </button>
  );
};

const WebinarTape = () => {
  return (
    <div className="webinarTapeContainer">
      <AppTickerTape speed={1} rtl pauseOnHover>
        {[<WebinarTapeItem key={'webinar'} />]}
      </AppTickerTape>
    </div>
  );
};

export default WebinarTape;