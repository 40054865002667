import { NavigateOptions, To, useLocation, useNavigate } from "react-router-dom"
import { PagePath } from "../enums";
import { isNumber } from "lodash";

export const useAppNavigate = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const appNavigate = (to: To | number, options?: NavigateOptions) => {
    if (isNumber(to)) {
      navigate(to);
    } else {
      navigate(to, { state: { from: location.pathname as PagePath }, ...options });
    }
  }

  return { appNavigate, location };
}