import images from '../../../../utils/images';
import { getImageUrlForCss } from '../../../../utils/stringHelper';
import HowItWorksCard, { HowItWorksCardData } from './components/HowItWorksCard';
import './HowItWorks.scss';

export const howItWorksCardsData: HowItWorksCardData[] = [
  { id: 1, stepText: '1 шаг', image: images.howItWorksCardImage1, title: 'Регистрация в приложении' },
  { id: 2, stepText: '2 шаг', image: images.howItWorksCardImage2, title: 'Подача заявки' },
  { id: 3, stepText: '3 шаг', image: images.howItWorksCardImage3, title: 'Подтверждение PRO-аккаунта' },
  { id: 4, stepText: '4 шаг', image: images.howItWorksCardImage4, title: 'Размещение и продажа услуг' },
  { id: 5, stepText: '5 шаг', image: images.howItWorksCardImage5, title: 'Вывод и получение денег' },
]

const HowItWorks = ({ blockRef }: { blockRef: React.RefObject<HTMLDivElement> }) => {
  return (
    <div className="howItWorksContainerOuter" ref={blockRef}>
      <div className="howItWorksTitle">{'Как это работает?'}</div>

      <div className="howItWorksCardsContainer">
        <HowItWorksCard cardData={howItWorksCardsData[0]} style={{ gridArea: "c1" }} />
        <HowItWorksCard cardData={howItWorksCardsData[1]} style={{ gridArea: "c2"}} />

        <div
          className="howItWorksIllust"
          style={{ backgroundImage: getImageUrlForCss(images.howItWorksBigIllust), gridArea: 'i1' }}
        />

        <HowItWorksCard cardData={howItWorksCardsData[2]} style={{ gridArea: "c3"}} />

        <div
          className="howItWorksIllust"
          style={{
            backgroundImage: getImageUrlForCss(images.howItWorksSmallIllust),
            gridArea: 'i2',
          }}
        />

        <HowItWorksCard cardData={howItWorksCardsData[3]} style={{ gridArea: "c4"}} />
        <HowItWorksCard cardData={howItWorksCardsData[4]} style={{ gridArea: "c5"}} />
      </div>
    </div>
  );
};

export default HowItWorks;