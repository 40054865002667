import {
  companyMail,
  companyMailHref,
  companyPhone1,
  companyPhone1Href,
  companyPhone2,
  companyPhone2Href,
  privacyPolicyHref,
  socialMediaButtons,
} from '../../utils/appConstants';
import { scrollToTop } from '../../utils/helper';
import { useHeaderFooterMenuItems } from '../../utils/hooks/useHeaderFooterMenuItems';
import images from '../../utils/images';
import LogoBlock from '../LogoBlock/LogoBlock';
import SocialMediaButton from '../SocialMediaButton/SocialMediaButton';
import './Footer.scss';

const Footer = () => {
  const { menuItems } = useHeaderFooterMenuItems({});

  const currentYear = new Date().getFullYear();
  const copyrightLine = `2017–${currentYear} ©️ ООО «Этнамед»`;

  const companyInfo = [
    'Мед. услуги оказывает ООО «Медлейбл»',
    'ИНН: 7726747596',
    'ОГРН: 1147746562336',
  ].join('\r\n');

  const onScrollToTop = () => {
    scrollToTop(true);
  }

  return (
    <div className="footerContainer">
      <div className="footerTopBlock">
        <div className="footerTopBlockMainSection">
          <div className="footerTopBlockMainSectionLogoAndGoTopButton">
            <LogoBlock isClickable={true} />
            <button
              className="footerGoToTopButton footerHideOnDesktop opacityOnHover"
              onClick={onScrollToTop}
            >
              <div className="footerGoToTopButtonIconContainer">
                <img className="footerGoToTopButtonArrowIcon" src={images.arrowUp} />
              </div>
              {'Наверх'}
            </button>
          </div>

          <div className="footerSectionLinkButtonsContainer">
            {menuItems.map((item) => (
              <button
                key={item.text}
                className={`footerSectionLinkButton ${
                  item.disabled ? 'footerSectionLinkButtonDisabled' : ''
                }`}
                onClick={item.onClick}
                disabled={item.disabled}
              >
                {item.text}
              </button>
            ))}
          </div>
        </div>

        <div className="footerCompanyInfoDesktop">{companyInfo}</div>
      </div>

      <div className="footerStillHaveQuestionsText">{'Остались вопросы?'}</div>

      <div className="footerContactsSection">
        <div className="footerContactTypeContainer">
          <div className="footerContactItemsHeader">{'Техническая поддержка:'}</div>
          <a className="footerContactItem link opacityOnHover" href={companyPhone1Href}>
            {companyPhone1}
          </a>
          <a className="footerContactItem link opacityOnHover" href={companyPhone2Href}>
            {companyPhone2}
          </a>
        </div>
        <div className="footerContactTypeContainer">
          <div className="footerContactItemsHeader">{'Наша почта:'}</div>
          <a className="footerContactItem link opacityOnHover" href={companyMailHref}>
            {companyMail}
          </a>
        </div>
        <div className="footerContactTypeContainer">
          <div className="footerContactItemsHeader">{'Следите за нами в социальных сетях:'}</div>
          <div className="footerSocialMediaButtonsContainerDesktop">
            {socialMediaButtons.map((item) => (
              <SocialMediaButton size={48} item={item} key={item.href} />
            ))}
          </div>
        </div>
      </div>

      <div className="footerBottomBlock">
        <div className="footerBottomBlockInformation">
          <div className="footerCompanyInfoMobile">{companyInfo}</div>
          <div className="footerBottomBlockCopyrightAndPolicy">
            {copyrightLine}
            <a
              className="footerBottomBlockCopyrightAndPolicy opacityOnHover"
              href={privacyPolicyHref}
              target="_blank"
            >
              {'Политика конфиденциальности'}
            </a>
          </div>
        </div>

        <button className="footerGoToTopButton footerHideOnMobile" onClick={onScrollToTop}>
          <div className="footerGoToTopButtonIconContainer">
            <img className="footerGoToTopButtonArrowIcon" src={images.arrowUp} />
          </div>
          {'Наверх'}
        </button>
      </div>
    </div>
  );
};

export default Footer;
