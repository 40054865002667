import { telegramCommunityHref, vkGroupHref } from '../../../../../utils/appConstants';
import images from '../../../../../utils/images';
import './ReviewItemSocialMedia.scss';

const ReviewItemSocialMedia = () => {
  return (
    <div className="reviewItemSocialMediaContainer" draggable={false}>
      {'Подписывайтесь и следите за нашими обновлениями в социальных сетях:'}
      <div className="reviewItemSocialMediaButtons">
        <a className="reviewItemSocialMediaIconContainer opacityOnHover" href={vkGroupHref} target="_blank">
          <img className="reviewItemSocialMediaIcon" src={images.reviewsVkIcon} />
        </a>
        <a
          className="reviewItemSocialMediaIconContainer opacityOnHover"
          href={telegramCommunityHref}
          target="_blank"
        >
          <img
            className="reviewItemSocialMediaIcon"
            src={images.reviewsTgIcon}
            style={{ paddingRight: '5%', width: 26, height: 'auto' }}
          />
        </a>
      </div>
    </div>
  );
};

export default ReviewItemSocialMedia;