import { useDispatch } from 'react-redux';
import { setSectionToScrollTo } from '../../../../../redux/mainReducer';
import { SectionName } from '../../../../../utils/enums';
import images from '../../../../../utils/images';
import './MainBannerEarnBlock.scss';

const MainBannerEarnBlock = () => {
  const dispatch = useDispatch();
  const moveToCalculatorSection = () => {
    dispatch(setSectionToScrollTo(SectionName.Calculator));
  }
  
  return (
    <button className="mainBannerMainInfoEarnSection" onClick={moveToCalculatorSection}>
    <div className="mainBannerMainInfoEarnSectionTop">
      <div className="mainBannerMainInfoEarnSectionTopTitle">
        {'Зарабатывайте'}
      </div>
      <div className="mainBannerMainInfoEarnSectionTopPotentialSalaryText">
        {'до 160 000 ₽ в месяц'}
      </div>
      <div className="mainBannerMainInfoEarnSectionTopDescription">
        {'Рассчитайте свой доход на нашем онлайн калькуляторе'}
      </div>
    </div>

    <div className="mainBannerMainInfoEarnSectionBottom">
      <div className="mainBannerMainInfoEarnSectionBottomLeft" />

      <div className="mainBannerMainInfoEarnSectionBottomMiddle">
        <div className="mainBannerMainInfoEarnSectionBottomMiddleCircleCutout">
          <div className="mainBannerMainInfoEarnSectionBottomMiddleButton">
            <img
              className="mainBannerMainInfoEarnSectionBottomMiddleButtonArrow"
              src={images.arrowUp}
            />
          </div>
        </div>
      </div>

      <div className="mainBannerMainInfoEarnSectionBottomRight" />
    </div>
  </button>
  );
};

export default MainBannerEarnBlock;