import { CSSProperties } from 'react';
import './AppSliderDots.scss';

const AppSliderDots = ({
  count,
  selectedId,
  className,
  style,
}: {
  count: number;
  selectedId: number;
  className?: string;
  style?: CSSProperties;
}) => {
  return (
    <div className={`appSliderDotsContainer ${className || ''}`} style={style}>
      {Array.from({ length: count }, (_, index) => {
        const isSelected = index === selectedId;
        return <div key={index} className={isSelected ? 'appSliderDotSelected' : 'appSliderDot'} />;
      })}
    </div>
  );
};

export default AppSliderDots;
